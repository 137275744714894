import { takeLatest, all, put } from 'redux-saga/effects';
import api from 'api';
import { actions } from './slice';
import { actions as userActions } from 'models/user/slice';

export function* fetchSharedCustomerOrganizationsSaga(action) {
  const { payload } = action.payload;
  yield api({
    action,
    method: 'get',
    url: `/shared_customer/organizations/`,
    data: payload,
  });
}

export function* updateSharedCustomerOrganizationSaga(action) {
  yield api({
    action,
    method: 'patch',
    url: `/shared_customer/organization/`,
    data: action.payload,
    successMessage: 'Successfully updated shared customer!',
    failureMessage: 'An error occurred while updating shared customer.',
  });
}

export function* updateSharedCustomerOrganizationSuccessSaga(action) {
  const { payload } = action;
  if (payload.data.contact_person && payload.data.contact_person.email) {
    yield put({
      type: userActions.getCurrentUser,
    });
  }
}

export default function*() {
  yield all([
    takeLatest(
      actions.fetchSharedCustomerOrganizations,
      fetchSharedCustomerOrganizationsSaga
    ),
    takeLatest(
      actions.updateSharedCustomerOrganization,
      updateSharedCustomerOrganizationSaga
    ),
    takeLatest(
      actions.updateSharedCustomerOrganizationSuccess,
      updateSharedCustomerOrganizationSuccessSaga
    ),
  ]);
}
