import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Label,
  Button,
  Alert,
} from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { useHistory } from 'react-router-dom';
import { CUSTOMER_PAYMENT_TYPES, HUMAN_LEADS_STATUSES } from 'constants';
import useAction from 'hooks/useAction';
import { actions as organizationsActions } from 'models/organizations/slice';
import { actions as customersActions } from 'models/customers/slice';
import { actions as userActions } from 'models/user/slice';
import Payment from 'components/Payment';
import useSelector from 'hooks/useSelector';
import { tabs } from 'constants/organizations';
import Main from './Main';
import {
  createdOrganizationSelector,
  currentTabSelector,
  fieldErrorsSelector,
} from 'models/organizations/selectors';
import {
  hasOwnerOrManagerRoleSelector,
  rolesSelector,
  canEditDefaultWarehouseSelector,
  matchingUsersSelector,
  isMatchingUsersPendingSelector,
} from 'models/user/selectors';
import {
  paymentMethodsSelector,
  paymentTypesSelector,
  paymentTypesAvailableSelector,
} from 'models/customers/selectors';
import { allBrandsSelector } from 'models/brands/selectors';
import PreloadedBrandsField from 'components/PaginatedFields/PreloadedBrandsField';
import styles from './OrganizationForm.scss';
import PaymentDropdown from 'components/PaymentDropdown';
import Select from 'react-select';
import { selectStyles } from 'constants/selectStyles';
import PaymentTypesTable from 'components/PaymentTypesTable';
import capitalize from 'lodash/capitalize';
import PaymentMethodsTable from 'components/PaymentMethodsTable';
import { isOnlySingleRole } from 'utils/isOnlySingleRole';
import { PERMISSION_TYPES } from 'constants/permissions';
import ContactTab from './ContactTab/ContactTab';
import RequestPaymentTable from 'components/RequestPaymentTable';
import ExistingUserModal from 'components/OrganizationForm/ExistingUserModal';

// Constants
const snakeToCamel = str =>
  capitalize(
    str.toLowerCase().replace(/([-_][a-z])/g, group =>
      group
        .toUpperCase()
        .replace('-', ' ')
        .replace('_', ' ')
    )
  );

const TabbedOrganizationForm = ({
  disabled,
  disableEditing,
  onSubmit,
  model,
  submitTitle,
  isLead,
  isEditing,
  isCreate,
}) => {
  const history = useHistory();
  // Selectors
  const allBrands = useSelector(allBrandsSelector);
  const currentTab = useSelector(currentTabSelector);
  const createdOrganization = useSelector(createdOrganizationSelector);
  const paymentMethods = useSelector(paymentMethodsSelector);
  const paymentTypes = useSelector(paymentTypesSelector);
  const paymentTypesAvailable = useSelector(paymentTypesAvailableSelector);
  const fieldErrors = useSelector(fieldErrorsSelector);
  const canEditDefaultWarehouse = useSelector(canEditDefaultWarehouseSelector);
  const hasOwnerOrManagerRole = useSelector(hasOwnerOrManagerRoleSelector);
  const roles = useSelector(rolesSelector);
  const matchingUsers = useSelector(matchingUsersSelector) || [];
  const isMatchingUsersPending = useSelector(isMatchingUsersPendingSelector);

  // Variables
  const { lead_status } = model || {};
  const {
    country: customerCountry = '',
    state: customerState = '',
    city: customerCity = '',
    line1: customerLine1 = '',
    line2: customerLine2 = '',
    postal_code: customerPostalCode = '',
  } = model?.address || {};
  let buttonTitle;
  if (currentTab === tabs[1]) {
    buttonTitle = `${submitTitle} & Next`;
  } else if (currentTab === tabs[2]) {
    buttonTitle = `Save`;
  } else {
    buttonTitle = 'Next';
  }

  // States
  const [currentType, setCurrentType] = useState(
    model
      ? {
          label: model?.company_type,
          value: model?.company_type,
        }
      : null
  );
  const [currentLeadStatus, setCurrentLeadStatus] = useState(
    lead_status
      ? {
          label: HUMAN_LEADS_STATUSES[lead_status],
          value: lead_status,
        }
      : null
  );
  const [followUpDate, setFollowUpDate] = useState(
    model?.follow_up_date || null
  );
  const [defaultWarehouse, setDefaultWarehouse] = useState(null);
  const [salesRep, setSalesRep] = useState(
    model?.sales_rep
      ? {
          value: model.sales_rep.id,
          label: `${model.sales_rep.first_name} ${model.sales_rep.last_name}`,
        }
      : null
  );
  const [licenseNumber, setLicenseNumber] = useState(model?.license_number);
  const [currentBrand, setCurrentBrand] = useState({});
  const [isPaymentFormOpen, setPaymentFormOpen] = useState(false);
  const [isPaymentTypeOpen, setPaymentTypeOpen] = useState(false);
  const [country, setCountry] = useState(customerCountry);
  const [state, setState] = useState(customerState);
  const [postalCode, setPostalCode] = useState(customerPostalCode);
  const [city, setCity] = useState(customerCity);
  const [line1, setLine1] = useState(customerLine1);
  const [line2, setLine2] = useState(customerLine2);
  const [businessName, setBusinessName] = useState(model?.title);
  const [cardOptions, setCardOptions] = useState([]);
  const [paymentType, setPaymentType] = useState();
  const [companyTypeError, setCompanyTypeError] = useState(null);
  const [warehouseError, setWarehouseError] = useState(null);
  const [addressError, setAddressError] = useState(null);
  const [businessNameError, setBusinessNameError] = useState(null);
  const [licenseNumberError, setLicenseNumberError] = useState(null);
  const [countryError, setCountryError] = useState(null);
  const [stateError, setStateError] = useState(null);
  const [postalCodeError, setPostalCodeError] = useState(null);
  const [cityError, setCityError] = useState(null);
  const [line1Error, setLine1Error] = useState(null);
  const [leadStatusError, setLeadStatusError] = useState(null);
  const [createdOrganizationId, setCreatedOrganizationId] = useState(
    model?.id || null
  );
  const [selectedOrganization, setSelectedOrganization] = useState(
    model || null
  );
  const [showModal, setShowModal] = useState(false);

  const hasEmptyValuesInContactPeople = contactPeople => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return contactPeople.some(contact => {
      const {
        first_name,
        last_name,
        email,
        phone,
        is_payment_contact,
      } = contact;
      return (
        !first_name ||
        !last_name ||
        !email ||
        !emailRegex.test(email) ||
        !phone ||
        !is_payment_contact === null
      );
    });
  };

  const sanitizePhone = phone => phone.replace(/\D/g, '');
  const resetContactPeople = () =>
    setContactPeople({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      is_payment_contact: false,
    });

  const [contactPeople, setContactPeople] = useState(
    model?.contact_people || [
      {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        is_payment_contact: false,
      },
    ]
  );

  // Actions
  const setCurrentTab = useAction(organizationsActions.setCurrentTab);
  const resetFieldErrors = useAction(organizationsActions.resetFieldErrors);
  const resetBlockMainInformationTab = useAction(
    organizationsActions.resetBlockMainInformationTab
  );
  const fetchPaymentTypes = useAction(customersActions.fetchPaymentTypes);
  const createPaymentType = useAction(customersActions.createPaymentType);
  const removePaymentType = useAction(customersActions.removePaymentType);
  const makeDefaultPaymentType = useAction(
    customersActions.makeDefaultPaymentType
  );
  const fetchPaymentTypesAvailable = useAction(
    customersActions.fetchPaymentTypesAvailable
  );
  const removePaymentMethod = useAction(customersActions.removePaymentMethod);
  const makeDefaultPaymentMethod = useAction(
    customersActions.makeDefaultPaymentMethod
  );
  const editOrganization = useAction(organizationsActions.updateOrganization);
  const fetchUsers = useAction(userActions.fetchMatchingUsersByEmails);

  const isPaymentVisible =
    currentBrand?.id && createdOrganization && paymentTypes;
  const isSalesRep = isOnlySingleRole(roles, PERMISSION_TYPES.SALES_REP);

  // Memos
  const paymentTypeOptions = useMemo(() => {
    const options = paymentTypesAvailable.map(value => ({
      value,
      label: snakeToCamel(value),
    }));
    if (!paymentTypesAvailable.includes(CUSTOMER_PAYMENT_TYPES.CARD)) {
      options.push({ value: CUSTOMER_PAYMENT_TYPES.CARD, label: 'Card' });
    }
    if (
      isSalesRep &&
      !paymentTypesAvailable.includes(CUSTOMER_PAYMENT_TYPES.CASH)
    ) {
      options.push({ value: CUSTOMER_PAYMENT_TYPES.CASH, label: 'Cash' });
    }

    return options;
  }, [paymentTypesAvailable, isSalesRep]);

  const inPaymentTypeButtonVisible = useMemo(() => {
    if (isSalesRep) {
      return paymentTypes?.length === 0;
    }
    return hasOwnerOrManagerRole;
  }, [hasOwnerOrManagerRole, paymentTypes, isSalesRep]);

  const isDefaultWarehouseDisabled = useMemo(() => {
    if (isSalesRep) {
      return disableEditing ? !canEditDefaultWarehouse : false;
    }
    return !hasOwnerOrManagerRole;
  }, [
    hasOwnerOrManagerRole,
    isSalesRep,
    canEditDefaultWarehouse,
    disableEditing,
  ]);

  const validateEmails = async () => {
    const emails = contactPeople
      .filter(contact => !contact.id) // Only validate contacts without an ID
      .map(contact => contact.email);

    if (emails.length === 0) return false; // No emails to validate, return false for no matches

    try {
      await fetchUsers({ emails }); // Await API call
      return true; // Validation completed
    } catch (error) {
      console.error('Error validating emails:', error);
      return false; // Return false if the validation fails
    }
  };

  // Functions
  // Validation function to check required fields
  const validateFields = () => {
    let valid = true;

    // Reset previous errors
    setCompanyTypeError(null);
    setWarehouseError(null);
    setAddressError(null);
    setBusinessNameError(null);
    setLicenseNumberError(null);
    setCountryError(null);
    setStateError(null);
    setPostalCodeError(null);
    setCityError(null);
    setLine1Error(null);
    setLeadStatusError(null);

    // Check Company Type
    if (!currentType) {
      setCompanyTypeError('Please select a Company Type');
      valid = false;
    }

    // Check Business Name
    if (!businessName) {
      setBusinessNameError('Business Name is required');
      valid = false;
    }

    // Check License Number (only if it's required)
    if (!isLead && !licenseNumber) {
      setLicenseNumberError('License Number is required');
      valid = false;
    }

    // Check Lead Status
    if (isLead && !currentLeadStatus) {
      setLeadStatusError('Please select a Lead Status');
      valid = false;
    }

    // Check Default Warehouse
    if (!isLead && !defaultWarehouse) {
      setWarehouseError('Please select a Default Warehouse');
      valid = false;
    }

    // Check Address fields
    if (!country) {
      setCountryError('Country is required');
      valid = false;
    }
    if (!state) {
      setStateError('Province/State is required');
      valid = false;
    }
    if (!postalCode) {
      setPostalCodeError('Postal Code is required');
      valid = false;
    }
    if (!city) {
      setCityError('City is required');
      valid = false;
    }
    if (!line1) {
      setLine1Error('Street Number (Address Line 1) is required');
      valid = false;
    }

    return valid;
  };

  const clearError = field => {
    switch (field) {
      case 'companyType':
        setCompanyTypeError(null);
        break;
      case 'businessName':
        setBusinessNameError(null);
        break;
      case 'licenseNumber':
        setLicenseNumberError(null);
        break;
      case 'defaultWarehouse':
        setWarehouseError(null);
        break;
      case 'country':
        setCountryError(null);
        break;
      case 'state':
        setStateError(null);
        break;
      case 'postalCode':
        setPostalCodeError(null);
        break;
      case 'city':
        setCityError(null);
        break;
      case 'line1':
        setLine1Error(null);
        break;
      case 'leadStatus':
        setLeadStatusError(null);
        break;
      default:
        break;
    }
  };

  // Handlers
  const onPaymentTypeSaveHandler = () => {
    if (paymentType?.value && currentBrand?.id) {
      const customerId = createdOrganization.contact_person.id;
      createPaymentType({
        customerId,
        type: paymentType.value,
        brand_id: currentBrand.id,
      });
    }
  };

  const onPaymentTypeRemoveHandler = item => {
    const customerId = createdOrganization.contact_person.id;
    const id = item?.id;
    if (id && customerId) {
      removePaymentType({ customerId, id, type: item?.type });
    }
  };

  const onPaymentTypeMakeDefaultHandler = item => {
    const customerId = createdOrganization.contact_person.id;
    const id = item?.id;
    if (id && customerId) {
      makeDefaultPaymentType({ customerId, id });
    }
  };

  const onPaymentSuccessHandler = () => {
    if (createdOrganization?.contact_person?.id) {
      const customerId = createdOrganization.contact_person.id;
      fetchPaymentTypes(customerId);
    }
    setPaymentFormOpen(false);
    setPaymentTypeOpen(false);
    setPaymentType(null);
  };

  const onPaymentRemoveHandler = methodId => {
    if (createdOrganization?.contact_person?.id) {
      const customerId = createdOrganization.contact_person.id;
      removePaymentMethod({ id: methodId, customerId });
    }
  };

  const onPaymentMakeDefaultHandler = methodId => {
    if (createdOrganization?.contact_person?.id) {
      const customerId = createdOrganization.contact_person.id;
      makeDefaultPaymentMethod({ id: methodId, customerId });
    }
  };

  const onPaymentTypeChangeHandler = data => {
    setPaymentFormOpen(data.value === 'CARD');
    setPaymentType(data);
  };

  const onAddPaymentTypeHandler = () => {
    setPaymentTypeOpen(true);
  };

  const onAddCardHandler = () => {
    setPaymentFormOpen(true);
  };

  const goToNextTab = async () => {
    if (currentTab === tabs[0]) {
      if (!validateFields()) {
        return; // Stop navigation if validation fails
      }
      setCurrentTab(tabs[1]); // Move to the next tab
    } else if (currentTab === tabs[1]) {
      // Run handleSubmit to check for matching users or validation errors
      await handleSubmit();
    } else {
      setCurrentTab(tabs[2]); // Move to the final tab
    }
  };

  const handleAddContact = () => {
    setContactPeople(prevState => [
      ...prevState,
      {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        is_payment_contact: false,
      },
    ]);
  };

  const handleReplaceContactPerson = () => {
    const updatedContactPeople = contactPeople.map(contact => {
      const matchingUser = matchingUsers.find(
        user => user.email === contact.email
      );
      if (matchingUser) {
        return {
          ...contact,
          id: matchingUser.id, // Replace ID with the matched user's ID
          first_name: matchingUser.first_name,
          last_name: matchingUser.last_name,
          phone: matchingUser.phone,
        };
      }
      return contact; // No match, return the original contact
    });

    setContactPeople(updatedContactPeople); // Update the contacts
    setShowModal(false); // Close the modal
  };

  const handleModalClose = () => {
    setShowModal(false); // Close modal without changes
  };

  const handleBrandChange = selectedOption => {
    const fullBrand = allBrands.find(
      brand => brand.id === selectedOption.value
    );
    setCurrentBrand(fullBrand || {});
  };

  const handleSubmit = async event => {
    if (event) event.preventDefault();

    const emailsToValidate = contactPeople.filter(contact => !contact.id);

    // Step 1: If there are new emails, validate them and wait for completion
    if (emailsToValidate.length > 0) {
      const validationCompleted = await validateEmails(); // Await validation
      if (!validationCompleted) {
        console.error('Validation failed. Stopping submission.');
        return; // Stop if validation failed
      }
    }

    // Step 2: Check for matching users after validation
    const hasRealMatches =
      Array.isArray(matchingUsers) &&
      matchingUsers.some(user => {
        // Check if the user is a real match and not just "No matching users found"
        const isRealMatch =
          !user.message || user.message !== 'No matching users found';

        // Check if there's a contact person without an ID matching the user's email
        const isUnresolvedContact = contactPeople.some(
          contact => !contact.id && contact.email === user.email
        );

        return isRealMatch && isUnresolvedContact;
      });

    if (hasRealMatches) {
      setShowModal(true); // Show modal to resolve matches
      return; // Stop execution until matches are resolved
    }

    // Step 3: Validate fields before preparing payload
    if (!validateFields()) {
      return; // Stop if validation fails
    }

    // Step 4: Prepare sanitized payload
    const sanitizedContactPeople = contactPeople.map(contact => ({
      ...contact,
      phone: sanitizePhone(contact.phone),
    }));

    const payload = {
      title: businessName,
      company_type: currentType?.value,
      license_number: licenseNumber,
      default_warehouse_id: defaultWarehouse?.value,
      country,
      state,
      city,
      postal_code: postalCode,
      line1,
      line2,
      contact_people: sanitizedContactPeople,
      ...(createdOrganizationId && { id: createdOrganizationId }), // Include ID if editing
    };

    // Step 5: Submit the payload
    try {
      if (currentTab === tabs[2]) {
        if (createdOrganizationId) {
          await editOrganization(payload);
          history.push(`/commercial-customers/${createdOrganizationId}`); // Redirect after editing
        } else {
          console.warn('No organization ID found for editing.');
        }
      } else if (
        currentTab === tabs[1] &&
        (matchingUsers.length > 0 || !hasRealMatches)
      ) {
        const newOrganization = await onSubmit(payload);
        if (newOrganization?.id) {
          setCreatedOrganizationId(newOrganization.id);
          setSelectedOrganization(newOrganization);
          setCurrentTab(tabs[2]); // Move to Payment tab after creation
        }
      }
    } catch (error) {
      console.error('Error during submission:', error);
    }
  };

  useEffect(() => {
    if (!isMatchingUsersPending) {
      const hasRealMatches =
        Array.isArray(matchingUsers) &&
        matchingUsers.some(user => {
          // Check if the user is a real match and not just "No matching users found"
          const isRealMatch =
            !user.message || user.message !== 'No matching users found';

          // Check if there's a contact person without an ID matching the user's email
          const isUnresolvedContact = contactPeople.some(
            contact => !contact.id && contact.email === user.email
          );

          return isRealMatch && isUnresolvedContact;
        });

      if (hasRealMatches) {
        setShowModal(true); // Show modal for real matches
      }
    }
  }, [isMatchingUsersPending, matchingUsers]);

  useEffect(() => {
    resetFieldErrors();
  }, []);

  useEffect(() => {
    if (createdOrganization?.contact_person?.id) {
      const customerId = createdOrganization.contact_person.id;
      fetchPaymentTypes(customerId);
    }
  }, [hasOwnerOrManagerRole, createdOrganization]);

  useEffect(() => {
    const customerId = createdOrganization?.contact_person?.id;
    const brandId = currentBrand?.id;

    if (hasOwnerOrManagerRole && customerId && brandId) {
      fetchPaymentTypesAvailable({ customerId, brandId });
    }
  }, [hasOwnerOrManagerRole, createdOrganization, currentBrand]);

  useEffect(() => {
    return () => {
      resetBlockMainInformationTab();
      setCurrentTab(tabs[0]);
    };
  }, [resetBlockMainInformationTab, setCurrentTab]);

  useEffect(() => {
    setPaymentType(null);
  }, [paymentTypesAvailable]);

  useEffect(() => {
    setPaymentFormOpen(false);
    setPaymentTypeOpen(false);
    setPaymentType(null);
  }, [paymentTypes]);

  useEffect(() => {
    if (model?.default_warehouse) {
      const warehouseId = model?.default_warehouse?.id;
      const warehouseTitle = model?.default_warehouse?.title;
      setDefaultWarehouse({
        value: warehouseId,
        label: warehouseTitle,
      });
    }
    if (model?.contact_people) {
      setContactPeople(model.contact_people);
    }
  }, [model]);

  useEffect(() => {
    return () => {
      resetContactPeople(); // Clears data when user exits without submitting
    };
  }, []);

  // Watch for createdOrganization change to set ID and navigate to the next tab
  useEffect(() => {
    if (createdOrganization?.id) {
      setCreatedOrganizationId(createdOrganization.id); // Update state with organization ID
      setSelectedOrganization(createdOrganization);
      setCurrentTab(tabs[2]); // Move to Payment tab
    }
  }, [createdOrganization]); // Runs whenever createdOrganization changes

  return (
    <>
      <Row className="mb-2">
        <Col lg={12}>
          <div id="checkout-nav-pills-wizard" className="twitter-bs-wizard">
            <Nav
              pills
              justified
              className={classNames('twitter-bs-wizard-nav')}
            >
              <NavItem>
                <NavLink
                  className={classNames(
                    'font-weight-bold d-flex align-items-baseline justify-content-start px-1 py-3',
                    tabs[0] === currentTab && 'active'
                  )}
                  onClick={() => {
                    if (currentTab > 0 && !validateFields()) {
                      // Stop navigation if Tab 0 validation fails
                      return;
                    }
                    setCurrentTab(tabs[0]); // Navigate to Tab 0
                  }}
                >
                  <span className={classNames('step-number mr-3', styles.pill)}>
                    1
                  </span>
                  <span className="step-title pt-1">{tabs[0]}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classNames(
                    'font-weight-bold d-flex align-items-baseline justify-content-center px-1 py-3',
                    tabs[1] === currentTab && 'active'
                  )}
                  onClick={() => {
                    if (currentTab === tabs[0]) {
                      if (!validateFields()) {
                        // Stop navigation if Tab 0 validation fails
                        return;
                      }
                    }
                    setCurrentTab(tabs[1]); // Navigate to Tab 1
                  }}
                >
                  <span
                    className={classNames(
                      'step-number mr-3',

                      styles.pill
                    )}
                  >
                    2
                  </span>

                  <span className="step-title pt-1">{tabs[1]}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classNames(
                    'font-weight-bold d-flex align-items-baseline justify-content-end px-1 py-3',
                    tabs[2] === currentTab && 'active'
                  )}
                  onClick={() => {
                    if (currentTab === tabs[0] && !validateFields()) {
                      // Stop navigation if Tab 0 validation fails
                      return;
                    }
                    if (currentTab === tabs[1]) {
                      const isTab1Valid = handleSubmit({
                        preventDefault: () => {}, // Simulate event for validation
                      });
                      if (!isTab1Valid) {
                        // Stop navigation if Tab 1 validation fails
                        return;
                      }
                    }
                    setCurrentTab(tabs[2]); // Navigate to Tab 2
                  }}
                >
                  <span className={classNames('step-number mr-3', styles.pill)}>
                    3
                  </span>
                  <span className="step-title pt-1">{tabs[2]} </span>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <AvForm
            model={model}
            className="needs-validation"
            onValidSubmit={handleSubmit}
            validateAllFields
          >
            {currentTab === tabs[0] && (
              <>
                <Main
                  model={model}
                  disabled={disabled}
                  disableEditing={disableEditing}
                  currentType={currentType}
                  currentLeadStatus={currentLeadStatus}
                  defaultWarehouse={defaultWarehouse}
                  handleDefaultWarehouseChange={setDefaultWarehouse}
                  defaultWarehouseDisabled={isDefaultWarehouseDisabled}
                  country={country}
                  state={state}
                  postalCode={postalCode}
                  city={city}
                  line1={line1}
                  line2={line2}
                  setLine2={setLine2}
                  fieldErrors={fieldErrors}
                  isSalesRep={isSalesRep}
                  isLead={isLead}
                  followUpDate={followUpDate}
                  setFollowUpDate={setFollowUpDate}
                  salesRep={salesRep}
                  setSalesRep={setSalesRep}
                  licenseNumber={licenseNumber}
                  isEditing={isEditing}
                  isCreate={isCreate}
                  businessName={businessName}
                  companyTypeError={companyTypeError}
                  setCompanyTypeError={setCompanyTypeError}
                  warehouseError={warehouseError}
                  addressError={addressError}
                  businessNameError={businessNameError}
                  licenseNumberError={licenseNumberError}
                  countryError={countryError}
                  stateError={stateError}
                  postalCodeError={postalCodeError}
                  setPostalCodeError={setPostalCodeError}
                  cityError={cityError}
                  line1Error={line1Error}
                  leadStatusError={leadStatusError}
                  setCurrentLeadStatus={status => {
                    setCurrentLeadStatus(status);
                    clearError('leadStatus');
                  }}
                  setCurrentType={type => {
                    setCurrentType(type);
                    clearError('companyType');
                  }}
                  setBusinessName={name => {
                    setBusinessName(name);
                    clearError('businessName');
                  }}
                  setLicenseNumber={number => {
                    setLicenseNumber(number);
                    clearError('licenseNumber');
                  }}
                  setCountry={value => {
                    setCountry(value);
                    clearError('country');
                  }}
                  setState={value => {
                    setState(value);
                    clearError('state');
                  }}
                  setPostalCode={value => {
                    setPostalCode(value);
                    clearError('postalCode');
                  }}
                  setCity={value => {
                    setCity(value);
                    clearError('city');
                  }}
                  setLine1={value => {
                    setLine1(value);
                    clearError('line1');
                  }}
                />
              </>
            )}
            {currentTab === tabs[1] && (
              <>
                <ContactTab
                  contactPeople={contactPeople}
                  hasEmptyValuesInContactPeople={hasEmptyValuesInContactPeople}
                  setContactPeople={people => {
                    setContactPeople(people);
                    clearError('contactPeople');
                  }}
                  handleAddContact={() => {
                    clearError('contactPeople');
                    handleAddContact();
                  }}
                />
                {showModal && (
                  <ExistingUserModal
                    isOpen={showModal}
                    users={matchingUsers}
                    onReplace={handleReplaceContactPerson} // Pass handler
                    onClose={handleModalClose} // Handle modal close
                  />
                )}
              </>
            )}
            {currentTab === tabs[2] && (
              <>
                {!createdOrganizationId && (
                  <Alert color="warning">
                    <i className="mdi mdi-information" /> Please complete Step 1
                    and Step 2 before proceeding to add Payment Methods.
                  </Alert>
                )}
                {createdOrganizationId && (
                  <>
                    <div className={styles.subHeading}>
                      Request Payment Information
                    </div>
                    <Col md={8} className={styles.rptContainer}>
                      <RequestPaymentTable
                        organizationId={createdOrganizationId}
                        paymentMethods={paymentMethods}
                        contactEmail={
                          contactPeople.find(contact => {
                            return contact.is_payment_contact;
                          })?.email
                        }
                        title="Send email to the assigned payment contact person to request payment."
                      />
                    </Col>

                    <div className={styles.subHeading}>Add Payment Methods</div>
                    {/* Brand Selection and Add Payment/Card Buttons */}
                    <Row className="d-flex align-items-end mb-2 mt-4">
                      <Col md={3}>
                        <Label required htmlFor="brands">
                          Brand
                        </Label>
                        <PreloadedBrandsField
                          name="brand_id"
                          value={currentBrand}
                          onChange={handleBrandChange}
                        />
                      </Col>
                      {currentBrand?.id &&
                        currentBrand?.active_stripe_acquirer?.public_key && (
                          <Col className="d-flex justify-content-end">
                            {inPaymentTypeButtonVisible ? (
                              <Button
                                onClick={onAddPaymentTypeHandler}
                                color="success"
                              >
                                <i className="mdi mdi-plus font-size-18 mr-1 align-middle" />
                                Add Payment Type
                              </Button>
                            ) : (
                              <Button
                                onClick={onAddCardHandler}
                                color="success"
                              >
                                <i className="mdi mdi-plus font-size-18 mr-1 align-middle" />
                                Add Card
                              </Button>
                            )}
                          </Col>
                        )}
                    </Row>
                    {isPaymentVisible && paymentTypeOptions?.length > 0 && (
                      <Row className="my-2 align-items-center">
                        {isPaymentTypeOpen && (
                          <>
                            <Col md={3}>
                              <Select
                                id="payment_type"
                                value={paymentType}
                                onChange={onPaymentTypeChangeHandler}
                                options={paymentTypeOptions}
                                styles={selectStyles}
                                placeholder="Select Payment type"
                              />
                            </Col>
                            {paymentType?.value != null &&
                              paymentType.value !== 'CARD' && (
                                <Col md={3}>
                                  <Button
                                    onClick={onPaymentTypeSaveHandler}
                                    color="primary"
                                  >
                                    Save
                                  </Button>
                                </Col>
                              )}
                          </>
                        )}
                        <Col className="mt-2" md={12}>
                          {currentBrand?.active_stripe_acquirer?.public_key ? (
                            <Payment
                              stripeToken={
                                currentBrand?.active_stripe_acquirer?.public_key
                              }
                              currentCustomerId={selectedOrganization?.id}
                              currentBrand={currentBrand}
                              cardOptions={cardOptions}
                              setCardOptions={setCardOptions}
                              withPaymentMethodsTable={false}
                              onSuccess={onPaymentSuccessHandler}
                              isFormHidden={!isPaymentFormOpen}
                              contactPerson={
                                selectedOrganization?.contact_person
                              }
                            />
                          ) : (
                            <Alert color="warning">
                              <i className="mdi mdi-information" />
                              <b>No active acquirer</b>
                              <p>Please add one in brands settings.</p>
                            </Alert>
                          )}
                        </Col>
                      </Row>
                    )}
                    {currentBrand?.active_stripe_acquirer?.public_key &&
                    currentBrand?.id ? (
                      <Row>
                        {paymentMethods?.length !== 0 &&
                          paymentTypes?.length !== 0 && (
                            <Col md={12}>
                              <PaymentTypesTable
                                paymentTypes={paymentTypes}
                                onMakeDefault={onPaymentTypeMakeDefaultHandler}
                                onDelete={onPaymentTypeRemoveHandler}
                                isActionVisible={hasOwnerOrManagerRole}
                              />
                            </Col>
                          )}
                        {paymentMethods?.length > 0 && (
                          <Col md={12}>
                            <PaymentMethodsTable
                              title="Default Card for Card Transactions"
                              paymentMethods={paymentMethods?.filter(
                                method => method?.brand_id === currentBrand.id
                              )}
                              actionNode={id => (
                                <PaymentDropdown
                                  onMakeDefault={() =>
                                    onPaymentMakeDefaultHandler(id)
                                  }
                                  onDelete={() => onPaymentRemoveHandler(id)}
                                />
                              )}
                            />
                          </Col>
                        )}
                      </Row>
                    ) : null}
                  </>
                )}
              </>
            )}

            <Row className="mt-2">
              <Col>
                <Button
                  color="primary"
                  onClick={event => {
                    if (currentTab === tabs[2]) {
                      handleSubmit(event);
                    } else {
                      goToNextTab();
                    }
                  }}
                >
                  {buttonTitle}
                </Button>
              </Col>
            </Row>
          </AvForm>
        </Col>
      </Row>
    </>
  );
};

TabbedOrganizationForm.propTypes = {
  onSubmit: PropTypes.func,
  model: PropTypes.object,
  disableEditing: PropTypes.bool,
  disabled: PropTypes.bool,
  submitTitle: PropTypes.string,
  isLead: PropTypes.bool,
  isEditing: PropTypes.bool,
  isCreate: PropTypes.bool,
};

export default TabbedOrganizationForm;
