import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
} from 'reactstrap';

const ExistingUserModal = ({ isOpen, users, onReplace, onClose }) => {
  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>Matching Users Found</ModalHeader>
      <ModalBody>
        <p>
          The following users were found based on the emails provided. Please
          select <b>Replace</b> to add the listed contacts or <b>Cancel</b> to
          make further changes.
        </p>
        <Table bordered>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Phone</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user.id}>
                <td>{user.first_name || ''}</td>
                <td>{user.last_name || ''}</td>
                <td>{user.email}</td>
                <td>{user.phone || ''}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onReplace}>
          Replace
        </Button>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ExistingUserModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
  onReplace: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ExistingUserModal;
