export const tabs = ['Main Information', 'Contact', 'Payment Methods'];

export const ACTIVITY_TYPES = [
  { value: 'EMAIL', label: 'Email' },
  { value: 'PHONE', label: 'Phone call' },
  { value: 'MEETING', label: 'Meeting' },
];

export const HUMAN_ACTIVITY_TYPES = {
  EMAIL: 'Email',
  PHONE: 'Phone call',
  MEETING: 'Meeting',
};
