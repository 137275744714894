import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useAction from 'hooks/useAction';
import { Row, Col, Label, Button, Alert } from 'reactstrap';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import PreloadedBrandsField from 'components/PaginatedFields/PreloadedBrandsField';
import Payment from 'components/Payment';
import { actions as customersActions } from 'models/customers/slice';
import {
  paymentMethodsSelector,
  paymentTypesSelector,
  intentFailureSelector,
} from 'models/customers/selectors';
import { sharedCustomerSelector } from 'models/sharedCustomer/selectors';
import { emailSelector } from 'models/user/selectors';
import PaymentMethodsTable from 'components/PaymentMethodsTable';
import PaymentDropdown from 'components/PaymentDropdown';
import { allBrandsSelector } from 'models/brands/selectors';
import useApiRequest from 'hooks/useApiRequest';
import { showErrorMessage } from 'utils/notification';
import { AsyncPaginate } from 'react-select-async-paginate';
import { getNormalText } from 'utils/getNormalText';
import PaymentTypesTable from 'components/PaymentTypesTable';

const PaymentMethodsSummary = ({ title }) => {
  // Selectors
  const currentUserEmail = useSelector(emailSelector);
  const paymentMethods = useSelector(paymentMethodsSelector);
  const paymentTypes = useSelector(paymentTypesSelector);
  const intentFailure = useSelector(intentFailureSelector);
  const sharedCustomer = useSelector(sharedCustomerSelector);
  const allBrands = useSelector(allBrandsSelector);
  const request = useApiRequest();
  // Actions
  const fetchPaymentTypes = useAction(customersActions.fetchPaymentTypes);
  // const createPaymentType = useAction(customersActions.createPaymentType);
  const fetchPaymentMethods = useAction(customersActions.fetchPaymentMethods);
  const clearPaymentMethods = useAction(customersActions.clearPaymentMethods);
  const removePaymentMethod = useAction(customersActions.removePaymentMethod);
  const makeDefaultPaymentMethod = useAction(
    customersActions.makeDefaultPaymentMethod
  );

  // States
  const [currentWinery, setCurrentWinery] = useState(
    allBrands.find(brand => brand.id === localStorage.getItem('lastBrandID')) ||
      {}
  );

  // Constants
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [cardOptions, setCardOptions] = useState([]);
  const [canAddPaymentMethod, setCanAddPaymentMethod] = useState(false);
  const [isPaymentMethodVisible, setIsPaymentMethodVisible] = useState(true);
  const [isPaymentMethodFormOpen, setPaymentMethodFormOpen] = useState(false);

  // Fetch shared customer data
  const fetchSharedCustomerOrganizations = ({ page = 1, search }) => {
    return request({
      url: `/shared_customer/organizations/?page=${page}&title_contains=${search}`,
      data: { current_email: currentUserEmail },
    });
  };

  const loadOrganizationOptions = async (search, loadedOptions, { page }) => {
    if (currentUserEmail) {
      try {
        const res = await fetchSharedCustomerOrganizations({ search, page });
        if (res.results && res.results.length === 0) {
          showErrorMessage(
            'Error!',
            'No Business organization found for customer'
          );
        } else {
          return {
            options: res.results || [],
            hasMore: res?.pagination?.next_page,
            additional: {
              page: page + 1,
            },
          };
        }
      } catch (err) {
        Object.entries(err.response?.data ?? {}).forEach(([key, value]) => {
          const preparedKey = getNormalText(key);
          showErrorMessage(
            preparedKey.toString(),
            value.toString() || 'Bad request'
          );
        });
        return {
          options: [],
          hasMore: true,
          additional: { page },
        };
      }
    }
    return {
      options: [],
    };
  };

  // Use Effects
  useEffect(() => {
    const initializeDefaultOption = async () => {
      const result = await loadOrganizationOptions('', [], { page: 1 });
      if (result?.options?.length > 0) {
        setSelectedOrganization(result.options[0]);
      }
    };
    initializeDefaultOption();
  }, [currentWinery]);

  useEffect(() => {
    if (currentWinery?.id && selectedOrganization?.id) {
      if (
        currentWinery?.active_stripe_acquirer?.public_key &&
        selectedOrganization?.contact_person?.id &&
        !intentFailure
      ) {
        setCanAddPaymentMethod(true);
        setIsPaymentMethodVisible(true);
      } else {
        setCanAddPaymentMethod(false);
        setIsPaymentMethodVisible(false);
      }
    } else {
      setIsPaymentMethodVisible(false);
    }
    setPaymentMethodFormOpen(false);
    clearPaymentMethods();
  }, [currentWinery, selectedOrganization]);

  useEffect(() => {
    if (selectedOrganization?.contact_person?.id) {
      fetchPaymentTypes(selectedOrganization.contact_person.id);
    }
    if (selectedOrganization?.id && selectedOrganization.contact_person?.id)
      fetchPaymentMethods({
        id: selectedOrganization?.contact_person?.id,
        organization_id: selectedOrganization?.id,
      });
  }, [currentWinery, selectedOrganization, fetchPaymentTypes]);

  useEffect(() => {
    if (intentFailure) {
      setCanAddPaymentMethod(false);
    } else {
      setCanAddPaymentMethod(true);
    }
  }, [intentFailure]);

  // Handlers
  const handleWineryChange = selectedOption => {
    const fullWinery = allBrands.find(
      brand => brand.id === selectedOption.value
    );
    setCurrentWinery(fullWinery || {});
    localStorage.setItem('lastBrandID', selectedOption.value);
    setSelectedOrganization(null);
    clearPaymentMethods();
  };

  const onAddCardHandler = () => {
    setPaymentMethodFormOpen(true);
  };

  const onPaymentSuccessHandler = () => {
    if (sharedCustomer?.contact_person?.id) {
      fetchPaymentTypes(selectedOrganization.contact_person.id);
    }
    setPaymentMethodFormOpen(false);
  };

  const onPaymentMakeDefaultHandler = methodId => {
    if (selectedOrganization?.contact_person?.id) {
      const customerId = selectedOrganization.contact_person.id;
      makeDefaultPaymentMethod({
        id: methodId,
        customerId,
        organizationId: selectedOrganization.id,
      });
    }
  };

  const onPaymentRemoveHandler = methodId => {
    if (selectedOrganization?.contact_person?.id) {
      const customerId = selectedOrganization.contact_person.id;
      removePaymentMethod({
        id: methodId,
        customerId,
        organizationId: selectedOrganization.id,
      });
    }
  };

  // Variables
  const paymentTypesData = paymentTypes.filter(
    type => type?.brand_id === currentWinery?.id
  );

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row className="d-flex align-items-end mb-2">
        <Col md={4}>
          <Label htmlFor="winery">Select Winery</Label>
          <PreloadedBrandsField
            value={
              currentWinery
                ? { value: currentWinery.id, label: currentWinery.title }
                : null
            }
            onChange={handleWineryChange}
            defaultBrandID={parseInt(localStorage.getItem('lastBrandID'), 10)}
          />
        </Col>
      </Row>
      <Row className="d-flex align-items-end mb-2">
        <Col md={4}>
          <Label htmlFor="winery">Select Business</Label>
          <AsyncPaginate
            name="organization_id"
            debounceTimeout={1000}
            defaultValue={selectedOrganization}
            value={selectedOrganization}
            loadOptions={loadOrganizationOptions}
            onChange={data => {
              setSelectedOrganization(data);
            }}
            additional={{
              page: 1,
            }}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.title}
            menuPlacement="bottom"
            maxMenuHeight={190}
          />
        </Col>
      </Row>
      <Row>
        {selectedOrganization?.id && currentWinery?.id && (
          <Col md={12}>
            <PaymentTypesTable paymentTypes={paymentTypesData} />
          </Col>
        )}
      </Row>
      <Row>
        {isPaymentMethodVisible && (
          <>
            {canAddPaymentMethod && selectedOrganization?.id ? (
              <Col className="d-flex justify-content-end mb-2">
                <Button onClick={onAddCardHandler} color="success">
                  <i className="mdi mdi-plus font-size-18 mr-1 align-middle" />
                  Add Card
                </Button>
              </Col>
            ) : (
              <Alert color="warning">
                <i className="mdi mdi-information" />
                <b>Payment methods are not available</b>
                <br />
                {`There are no payment types available for this Winery. Please contact ${currentWinery?.title} for support.`}
              </Alert>
            )}
          </>
        )}
      </Row>
      <Row>
        {isPaymentMethodVisible && selectedOrganization?.id && (
          <Col className="mt-2" md={12}>
            <Payment
              stripeToken={currentWinery?.active_stripe_acquirer?.public_key}
              currentCustomerId={selectedOrganization?.id}
              currentBrand={currentWinery}
              cardOptions={cardOptions}
              setCardOptions={setCardOptions}
              isFormHidden={!isPaymentMethodFormOpen}
              onSuccess={onPaymentSuccessHandler}
              isSharedCustomer
              contactPerson={selectedOrganization?.contact_person}
            />
            <PaymentMethodsTable
              title="Credit Cards"
              paymentMethods={paymentMethods.filter(
                method => method?.brand_id === currentWinery?.id
              )}
              actionNode={id => (
                <PaymentDropdown
                  onMakeDefault={() => onPaymentMakeDefaultHandler(id)}
                  onDelete={() => onPaymentRemoveHandler(id)}
                />
              )}
            />
          </Col>
        )}
      </Row>
    </PageWrapper>
  );
};

PaymentMethodsSummary.propTypes = {
  title: PropTypes.string,
};

export default PaymentMethodsSummary;
