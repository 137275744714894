import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const sharedCustomer = createSlice({
  name: 'sharedCustomer',
  initialState: {
    isPending: false,
    sharedCustomer: {},
    sharedCustomers: {},
    updatdSharedCustomer: {},
    fieldErrors: {},
  },
  reducers: {
    fetchSharedCustomerOrganizations: state => {
      state.isPending = true;
    },
    fetchSharedCustomerOrganizationsSuccess(state, { payload }) {
      state.isPending = false;
      state.sharedCustomers = payload.data.results;
      state.updatdSharedCustomer = {};
    },
    fetchSharedCustomerOrganizationsFailure: state => {
      state.isPending = false;
    },
    updateSharedCustomerOrganization: state => {
      state.isPending = true;
    },
    updateSharedCustomerOrganizationSuccess(state, { payload }) {
      state.isPending = false;
      // state.sharedCustomer = payload.data;
      state.updatdSharedCustomer = payload.data;
    },
    updateSharedCustomerOrganizationFailure: state => {
      state.isPending = false;
    },
    resetFieldErrors: state => {
      state.fieldErrors = {};
    },
  },
});

export const actions = actionTypes(sharedCustomer.actions);

export default sharedCustomer.reducer;
