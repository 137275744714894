import { takeLatest, all, put } from 'redux-saga/effects';

import api from 'api';

import * as queryString from 'query-string';
import { actions } from './slice';
import { generateURL } from 'utils/generateURL';

export function* fetchCustomersSaga(action) {
  const { payload } = action;

  const { requestURL } = yield generateURL({
    payload,
    url: '/wineclub-customers/',
    requestURL: '/customers/',
    options: {
      arrayFormat: 'comma',
    },
    setQuery: payload.setQuery,
  });

  yield api({
    action,
    method: 'get',
    url: requestURL,
  });
}

export function* fetchCustomerSaga(action) {
  const { payload } = action;
  yield api({
    action,
    method: 'get',
    url: `/customers/${payload}`,
  });
}

export function* inviteCustomersSaga(action) {
  const { payload } = action;
  yield api({
    action,
    method: 'post',
    data: payload,
    url: '/customers/invite',
    successMessage: 'Successfully sent email!',
  });
}

export function* wineNotificationCustomersSaga(action) {
  const { payload } = action;
  yield api({
    action,
    method: 'post',
    data: payload,
    url: '/customers/wine_select_notification',
    successMessage: 'Successfully sent email!',
  });
}

export function* createCustomerSaga(action) {
  const { payload } = action;
  yield api({
    action,
    method: 'post',
    data: payload,
    url: '/customers/',
    successMessage: 'Successfully created new customer!',
    showResponseError: true,
  });
}

export function* addCustomerNoteSaga(action) {
  const { payload } = action;
  yield api({
    action,
    method: 'post',
    data: {
      text: payload.note,
    },
    url: `/customers/${payload.id}/note`,
    successMessage: 'Successfully added customer note!',
    showResponseError: true,
  });
}

export function* editCustomerSaga(action) {
  const { payload } = action;
  yield api({
    action,
    method: 'patch',
    data: payload,
    url: `/customers/${payload.id}`,
    successMessage: 'Successfully updated customer!',
    showResponseError: true,
  });
}

export function* setupIntentSaga(action) {
  const { payload } = action;
  yield api({
    action,
    method: 'post',
    url: `/customers/${payload.customer_id}/payments/setup_intent`,
    data: {
      brand_id: payload.brand_id,
    },
    showResponseError: !payload.isSharedCustomer,
  });
}

export function* sendPaymentMethodSaga(action) {
  const { payload } = action;
  yield api({
    action,
    method: 'post',
    url: `/customers/${payload.customer_id}/${payload.brand_id}/payments/payment_method/`,
    data: payload.result,
  });
}

export function* fetchPaymentTypesAvailableSaga(action) {
  const { brandId, customerId } = action.payload;
  yield api({
    action,
    method: 'get',
    url: `/customers/${customerId}/payment_types/available?brand_id=${brandId}`,
  });
}

export function* fetchPaymentTypesSaga(action) {
  const customerId = action.payload;
  yield api({
    action,
    method: 'get',
    url: `/customers/${customerId}/payment_types`,
  });
}

export function* createPaymentTypeSaga(action) {
  const { payload } = action;
  yield api({
    action,
    method: 'post',
    data: payload,
    url: `/customers/${payload.customerId}/payment_types`,
    successMessage: 'Successfully created new payment type!',
    showResponseError: true,
  });
}

export function* removePaymentTypeSaga(action) {
  const { id, customerId } = action.payload;
  yield api({
    action,
    method: 'DELETE',
    url: `/customers/${customerId}/payment_types/${id}`,
  });
}

function* makeDefaultPaymentTypeSaga(action) {
  const { id, customerId } = action.payload;
  yield api({
    action,
    method: 'POST',
    url: `/customers/${customerId}/payment_types/${id}/make_default`,
  });
}

export function* fetchPaymentMethodsSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: `/customers/${action.payload.id}/payment_methods`,
  });
  yield api({
    action,
    method: 'get',
    url,
  });
}

function* removePaymentMethodSaga(action) {
  const { id, customerId } = action.payload;
  yield api({
    action,
    method: 'DELETE',
    url: `/customers/${customerId}/payment_methods/${id}`,
  });
}

function* makeDefaultPaymentMethodSaga(action) {
  const { id, customerId } = action.payload;
  yield api({
    action,
    method: 'POST',
    url: `/customers/${customerId}/payment_methods/${id}/make_active`,
  });
}

function* makeDefaultPaymentMethodSuccessSaga(action) {
  yield put({
    type: actions.fetchPaymentTypes,
    payload: action.payload.inResponseTo.customerId,
  });
}

function* removePaymentMethodSuccessSaga(action) {
  const { customerId, organizationId } = action.payload.inResponseTo;
  yield fetchPaymentMethodsSaga({
    payload: {
      id: customerId,
      organization_id: organizationId,
    },
  });
}

function* removePaymentMethodFailureSaga(action) {
  const { customerId, organizationId } = action.payload.inResponseTo;
  yield fetchPaymentMethodsSaga({
    payload: {
      id: customerId,
      organization_id: organizationId,
    },
  });
}

function* fetchAbstractCustomerSaga(action) {
  const { email } = action.payload;
  yield api({
    action,
    method: 'GET',
    url: `/customers/abstract/${email}`,
  });
}

function* archiveCustomerSaga(action) {
  yield api({
    action,
    method: 'POST',
    url: `/customers/${action.payload}/archive`,
  });
}

function* restoreCustomerSaga(action) {
  yield api({
    action,
    method: 'POST',
    url: `/customers/${action.payload}/restore`,
  });
}

function* toggleWineClubMemberActivitySaga(action) {
  yield api({
    action,
    method: 'POST',
    url: `/wineclub-members/${action.payload}/toggle_activity`,
  });
}

function* sendCustomEmailSaga(action) {
  yield api({
    action,
    method: 'POST',
    url: '/customers/send_custom_email',
    data: action.payload,
    successMessage: 'Mail successfully sent!',
  });
}

function* sendRecoverPasswordSaga(action) {
  const parameters = queryString.stringify(action.payload);
  yield api({
    action,
    method: 'GET',
    url: `/customers/recover_password?${parameters}`,
    data: action.payload,
    successMessage: 'Mail successfully sent!',
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchCustomers, fetchCustomersSaga),
    takeLatest(actions.fetchCustomer, fetchCustomerSaga),
    takeLatest(actions.inviteCustomers, inviteCustomersSaga),
    takeLatest(
      actions.wineNotificationCustomers,
      wineNotificationCustomersSaga
    ),
    takeLatest(actions.createCustomer, createCustomerSaga),
    takeLatest(actions.editCustomer, editCustomerSaga),
    takeLatest(actions.addCustomerNote, addCustomerNoteSaga),
    takeLatest(actions.setupIntent, setupIntentSaga),
    takeLatest(actions.sendPaymentMethod, sendPaymentMethodSaga),
    takeLatest(
      actions.fetchPaymentTypesAvailable,
      fetchPaymentTypesAvailableSaga
    ),
    takeLatest(actions.fetchPaymentTypes, fetchPaymentTypesSaga),
    takeLatest(actions.createPaymentType, createPaymentTypeSaga),
    takeLatest(actions.removePaymentType, removePaymentTypeSaga),
    takeLatest(actions.makeDefaultPaymentType, makeDefaultPaymentTypeSaga),
    takeLatest(actions.fetchPaymentMethods, fetchPaymentMethodsSaga),
    takeLatest(actions.removePaymentMethod, removePaymentMethodSaga),
    takeLatest(actions.makeDefaultPaymentMethod, makeDefaultPaymentMethodSaga),
    takeLatest(
      actions.makeDefaultPaymentMethodSuccess,
      makeDefaultPaymentMethodSuccessSaga
    ),
    takeLatest(
      actions.removePaymentMethodSuccess,
      removePaymentMethodSuccessSaga
    ),
    takeLatest(
      actions.removePaymentMethodFailure,
      removePaymentMethodFailureSaga
    ),
    takeLatest(actions.fetchAbstractCustomer, fetchAbstractCustomerSaga),
    takeLatest(actions.archiveCustomer, archiveCustomerSaga),
    takeLatest(actions.restoreCustomer, restoreCustomerSaga),
    takeLatest(
      actions.toggleWineClubMemberActivity,
      toggleWineClubMemberActivitySaga
    ),
    takeLatest(actions.sendCustomEmail, sendCustomEmailSaga),
    takeLatest(actions.sendRecoverPasswordEmail, sendRecoverPasswordSaga),
  ]);
}
