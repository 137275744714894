import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { useParams } from 'react-router-dom';

import { actions as organizationsActions } from 'models/organizations/slice';
import {
  organizationSelector,
  isPendingSelector as isOrganizationsPendingSelector,
} from 'models/organizations/selectors';
import Preloader from 'components/Preloader';
import TabbedOrganizationForm from 'components/OrganizationForm/TabbedOrganizationForm';
import { hasOwnerOrManagerRoleSelector } from 'models/user/selectors';

const EditOrganization = ({ title }) => {
  const { id } = useParams();
  const editOrganization = useAction(organizationsActions.updateOrganization);
  const fetchOrganization = useAction(organizationsActions.fetchOrganization);
  const organization = useSelector(organizationSelector);
  const organizationsPending = useSelector(isOrganizationsPendingSelector);

  const isOwnerOrManagerRole = useSelector(hasOwnerOrManagerRoleSelector);

  const isFormDisabled = !isOwnerOrManagerRole;

  useEffect(() => {
    fetchOrganization(id);
  }, [fetchOrganization, id]);

  if (organizationsPending) return <Preloader />;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Edit Commercial Customer"
        breadcrumbItems={[
          {
            title: 'Back to commercial customers',
            link: '/commercial-customers/',
            withSearch: true,
          },
        ]}
      />

      <TabbedOrganizationForm
        onSubmit={editOrganization}
        submitTitle="Save"
        model={organization}
        disabled={isFormDisabled}
        disableEditing
        isEditing
      />
    </PageWrapper>
  );
};

EditOrganization.propTypes = {
  title: PropTypes.string,
};

export default EditOrganization;
