import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import useAction from 'hooks/useAction';
import { Row, Col, Card, CardBody } from 'reactstrap';
import useSelector from 'hooks/useSelector';
import PageWrapper from 'components/PageWrapper';
import NotesTable from 'components/NotesTable';
import ActivityTable from 'components/ActivityTable';
import { actions as organizationsActions } from 'models/organizations/slice';
import { isOnlySingleRole } from 'utils/isOnlySingleRole';
import { PERMISSION_TYPES } from 'constants/permissions';

import s from './LeadsShow.scss';
import {
  organizationSelector,
  isPendingSelector,
  isPendingNotesSelector,
  isPendingActivitiesSelector,
} from 'models/organizations/selectors';
import {
  rolesSelector,
  hasOwnerOrManagerRoleSelector,
} from 'models/user/selectors';
import Preloader from 'components/Preloader';
import Breadcrumbs from 'components/Breadcrumbs';
import isEmpty from 'lodash/isEmpty';
import { getNormalText } from 'utils/getNormalText';

const CardItem = ({ title, value }) => (
  <Card>
    <CardBody className={s.card}>
      <h4 className="card-title">{title}</h4>
      <span className="text-dark">{value}</span>
    </CardBody>
  </Card>
);

const LeadsShow = () => {
  const { id } = useParams();
  const fetchLead = useAction(organizationsActions.fetchOrganization);
  const lead = useSelector(organizationSelector);
  const pending = useSelector(isPendingSelector);
  const roles = useSelector(rolesSelector);

  const isSalesRep = isOnlySingleRole(roles, PERMISSION_TYPES.SALES_REP);
  const isOwnerOrManagerRole = useSelector(hasOwnerOrManagerRoleSelector);
  const pendingNotes = useSelector(isPendingNotesSelector);
  const pendingActivities = useSelector(isPendingActivitiesSelector);

  const isEditLinkVisible = isSalesRep || isOwnerOrManagerRole;

  React.useEffect(() => {
    fetchLead(id);
  }, [fetchLead, id, pendingNotes, pendingActivities]);

  if (pending) return <Preloader />;
  return (
    <PageWrapper>
      {isEditLinkVisible ? (
        <Breadcrumbs
          link={`/lead/${lead?.id}/edit`}
          title={`Edit ${lead?.title} #${lead?.id}`}
          breadcrumbItems={[
            {
              title: 'Back to Leads',
              link: '/leads/',
              withSearch: true,
            },
          ]}
        />
      ) : null}
      {!isEmpty(lead) && (
        <>
          <Row>
            <Col lg="3">
              <CardItem title="Title" value={lead.title} />
            </Col>
            <Col lg="3">
              <CardItem title="License Number" value={lead.license_number} />
            </Col>
            <Col lg="3">
              <CardItem title="Company Type" value={lead.company_type} />
            </Col>
            <Col lg="3">
              <CardItem
                title="Price Type"
                value={getNormalText(lead.price_type)}
              />
            </Col>
            {lead?.default_warehouse && (
              <Col lg="3">
                <CardItem
                  title="Default Warehouse"
                  value={lead.default_warehouse.title}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col lg="6">
              <CardItem
                title="Shipping Address"
                value={Object.values(lead?.address).join(' ')}
              />
            </Col>
            <Col lg="6">
              <CardItem
                title="Payment Contact Information"
                value={`
              ${lead.contact_person.first_name}
              ${lead.contact_person.last_name}
              ${lead.contact_person.email}
              ${lead.contact_person.phone || ''}
              `}
              />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <CardItem
                className={s.notes}
                title="Notes"
                value={<NotesTable notes={lead?.notes ?? []} isLead />}
              />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <CardItem
                className={s.notes}
                title="Activities"
                value={<ActivityTable activities={lead?.activities ?? []} />}
              />
            </Col>
          </Row>
        </>
      )}
    </PageWrapper>
  );
};

CardItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default LeadsShow;
