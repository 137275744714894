import React from 'react';
import PropTypes from 'prop-types';
import s from './Form.scss';
import formatMonth from 'utils/formatMonth';
import useAction from 'hooks/useAction';
import { actions as customersActions } from 'models/customers/slice';

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, Row, Col, Label } from 'reactstrap';
import useApiRequest from 'hooks/useApiRequest';
import { showErrorMessage } from 'utils/notification';
import Select from 'react-select';

const resetForm = () => {
  document.getElementById('first-name').value = '';
  document.getElementById('last-name').value = '';
};

const Form = ({
  setCurrentCard,
  currentCard,
  intent,
  brand,
  currentCustomerId,
  cardOptions,
  setCardOptions,
  withCardChoice,
  onSuccessSubmit,
  contactPerson,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const request = useApiRequest();
  const [loading, setLoading] = React.useState(false);

  const addPaymentMethod = useAction(customersActions.addPaymentMethod);

  const attachPaymentMethod = ({
    brand_id,
    customer_id,
    organization_id,
    data,
  }) => {
    return request({
      url: `/customers/${customer_id}/payments/payment_method`,
      method: 'POST',
      data: {
        ...data,
        brand_id,
        organization_id,
      },
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);
    if (!elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    const card = elements.getElement(CardElement);
    const options = {
      name: `${document.getElementById('first-name').value} ${
        document.getElementById('last-name').value
      }`,
    };

    if (!options.name.trim()) {
      showErrorMessage('Error!', 'Please enter your first and last name.');
      setLoading(false);
      return;
    }
    const result = await stripe.confirmCardSetup(intent.client_secret, {
      payment_method: {
        card,
        billing_details: {
          ...options,
        },
      },
    });

    if (result.error) {
      showErrorMessage('Error!', result.error.message);
      setLoading(false);
    } else {
      // Send the token to server.
      handleAttachPayment(result, card);
    }
  };

  const handleAttachPayment = (result, card) => {
    const payload = {
      brand_id: brand?.id,
      customer_id: contactPerson?.id || currentCustomerId,
      ...(contactPerson?.id && { organization_id: currentCustomerId }),
      data: result.setupIntent,
    };
    attachPaymentMethod(payload).then(res => {
      setLoading(false);
      resetForm();
      card.clear();
      if (withCardChoice) {
        setCardOptions([...cardOptions, res]);
        setCurrentCard(res);
      }
      addPaymentMethod({
        ...res,
        brand_title: brand.title,
        brand_id: brand?.id,
      });
      if (onSuccessSubmit) {
        onSuccessSubmit();
      }
    });
  };

  const handleCardChange = e => {
    setCurrentCard(e);
  };

  return (
    <>
      {withCardChoice && (
        <>
          <Row>
            <Col md={6}>
              <Label required htmlFor="payment_method">
                Payment Method
              </Label>
              <Select
                id="payment_method"
                options={cardOptions}
                onChange={handleCardChange}
                value={currentCard}
                getOptionValue={option => option}
                getOptionLabel={option =>
                  `**** **** **** ${
                    option.card_last_digits
                  } ${option.card_brand.toUpperCase()} ${formatMonth(
                    option?.card_exp_month
                  )}/${option?.card_exp_year.toString().slice(-2)}`
                }
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <Label>Or Add New Card</Label>
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col md={12}>
          <div className={s.group}>
            <label className={s.label}>
              <span>First Name</span>
              <input
                id="first-name"
                name="first-name"
                required
                className={s.field}
                placeholder="Jane"
              />
            </label>
            <label className={s.label}>
              <span>Last Name</span>
              <input
                id="last-name"
                name="last-name"
                required
                className={s.field}
                placeholder="Doe"
              />
            </label>
          </div>
          <CardElement
            options={{
              hidePostalCode: true,
              style: {
                base: {
                  color: '#32325d',
                  fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                  fontSmoothing: 'antialiased',
                  fontSize: '16px',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                },
                invalid: {
                  color: '#fa755a',
                  iconColor: '#fa755a',
                },
              },
            }}
          />
          <Button
            type="submit"
            color="primary"
            className="waves-effect my-4"
            onClick={handleSubmit}
            disabled={!stripe || loading}
          >
            Link Card
          </Button>
        </Col>
      </Row>
    </>
  );
};

Form.propTypes = {
  setCurrentCard: PropTypes.func,
  currentCard: PropTypes.object,
  intent: PropTypes.object,
  brand: PropTypes.object,
  currentCustomerId: PropTypes.object,
  cardOptions: PropTypes.array,
  setCardOptions: PropTypes.func,
  withCardChoice: PropTypes.bool,
  onSuccessSubmit: PropTypes.func,
  contactPerson: PropTypes.object,
};

export default Form;
