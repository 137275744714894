import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { actions as customersActions } from 'models/customers/slice';

import { Elements } from '@stripe/react-stripe-js';

import Form from './Form';
import { loadStripe } from '@stripe/stripe-js';
import {
  intentSelector,
  paymentMethodsSelector,
} from 'models/customers/selectors';
import isEmpty from 'lodash/isEmpty';
import PaymentMethodsTable from 'components/PaymentMethodsTable';

const Payment = ({
  stripeToken,
  stripeAccount,
  currentCustomerId,
  currentBrand,
  setCurrentCard,
  currentCard,
  cardOptions,
  setCardOptions,
  withCardChoice,
  withPaymentMethodsTable,
  onSuccess,
  actionNode,
  isFormHidden,
  isSharedCustomer,
  contactPerson,
}) => {
  const stripePromise = stripeAccount
    ? loadStripe(stripeToken, { stripeAccount })
    : loadStripe(stripeToken);
  const fetchPaymentMethods = useAction(customersActions.fetchPaymentMethods);
  const removePaymentMethod = useAction(customersActions.removePaymentMethod);
  const setupIntent = useAction(customersActions.setupIntent);
  const intent = useSelector(intentSelector);
  const paymentMethods = useSelector(paymentMethodsSelector);

  useEffect(() => {
    const stripeCustomerId = contactPerson
      ? contactPerson.id
      : currentCustomerId;
    setupIntent({
      customer_id: stripeCustomerId,
      brand_id: currentBrand?.id,
      isSharedCustomer,
      ...(contactPerson && { organization_id: currentCustomerId }),
    });
  }, [currentCustomerId, currentBrand, setupIntent]);

  useEffect(() => {
    fetchPaymentMethodsAny();
  }, [fetchPaymentMethods, currentCustomerId]);

  useEffect(() => {
    if (setCardOptions) {
      setCardOptions(
        paymentMethods.filter(method => method?.brand_id === currentBrand?.id)
      );
    }
  }, [setCardOptions, paymentMethods, currentBrand]);

  const onSuccessSubmit = () => {
    const stripeCustomerId = contactPerson
      ? contactPerson.id
      : currentCustomerId;
    setupIntent({
      customer_id: stripeCustomerId,
      brand_id: currentBrand?.id,
      isSharedCustomer,
      ...(contactPerson && { organization_id: currentCustomerId }),
    });
    fetchPaymentMethodsAny();
    onSuccess?.();
  };

  const fetchPaymentMethodsAny = () => {
    if (!currentCustomerId) return;
    const params = contactPerson?.id
      ? { id: contactPerson.id, organization_id: currentCustomerId }
      : { id: currentCustomerId };
    fetchPaymentMethods(params);
  };

  const onRemovePaymentMethod = methodId => {
    removePaymentMethod({
      id: methodId,
      customerId: currentCustomerId,
      organization_id: currentCustomerId,
    });
  };

  return (
    <>
      <Elements stripe={stripePromise}>
        <>
          {!isFormHidden && (
            <Form
              setCurrentCard={setCurrentCard}
              intent={intent}
              cardOptions={cardOptions}
              setCardOptions={setCardOptions}
              brand={currentBrand}
              currentCard={currentCard}
              currentCustomerId={currentCustomerId}
              withCardChoice={withCardChoice}
              onSuccessSubmit={onSuccessSubmit}
              contactPerson={contactPerson}
            />
          )}
          {withPaymentMethodsTable && !isEmpty(paymentMethods) && (
            <PaymentMethodsTable
              paymentMethods={paymentMethods.filter(
                method => method?.brand_id === currentBrand.id
              )}
              onRemovePaymentMethod={onRemovePaymentMethod}
              actionNode={actionNode}
            />
          )}
        </>
      </Elements>
    </>
  );
};

Payment.propTypes = {
  stripeToken: PropTypes.string,
  stripeAccount: PropTypes.string,
  currentCustomerId: PropTypes.number,
  currentBrand: PropTypes.object,
  setCurrentCard: PropTypes.func,
  cardOptions: PropTypes.array,
  currentCard: PropTypes.object,
  setCardOptions: PropTypes.func,
  withCardChoice: PropTypes.bool,
  isFormHidden: PropTypes.bool,
  withPaymentMethodsTable: PropTypes.bool,
  onSuccess: PropTypes.func,
  actionNode: PropTypes.node,
  isSharedCustomer: PropTypes.bool,
  contactPerson: PropTypes.object,
};

export default Payment;
