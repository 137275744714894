import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import useAction from 'hooks/useAction';
import { Row, Col, Card, CardBody } from 'reactstrap';
import useSelector from 'hooks/useSelector';
import PageWrapper from 'components/PageWrapper';
import { actions as organizationsActions } from 'models/organizations/slice';
import { actions as customersActions } from 'models/customers/slice';
import { isOnlySingleRole } from 'utils/isOnlySingleRole';
import { PERMISSION_TYPES } from 'constants/permissions';

import styles from './OrganizationsShow.scss';
import {
  organizationSelector,
  isPendingSelector,
} from 'models/organizations/selectors';
import {
  paymentMethodsSelector,
  paymentTypesSelector,
} from 'models/customers/selectors';
import {
  rolesSelector,
  hasOwnerOrManagerRoleSelector,
} from 'models/user/selectors';
import Preloader from 'components/Preloader';
import Breadcrumbs from 'components/Breadcrumbs';
import isEmpty from 'lodash/isEmpty';
import PaymentMethodsTable from 'components/PaymentMethodsTable';
import PaymentTypesTable from 'components/PaymentTypesTable';
import { getNormalText } from 'utils/getNormalText';
import RequestPaymentTable from 'components/RequestPaymentTable';
import { formatPhoneNumber } from 'utils/formatPhoneNumber';

const CardItem = ({ title, values, className }) => {
  // Constants
  const valueContent = Array.isArray(values) ? (
    values.map((val, index) => <div key={index}>{val}</div>)
  ) : (
    <span className="text-dark">{values}</span>
  );

  return (
    <Card>
      <CardBody className={className}>
        <h4 className="card-title">{title}</h4>
        {valueContent}
      </CardBody>
    </Card>
  );
};

const OrganizationsShow = () => {
  // Params
  const { id } = useParams();

  // Actions
  const fetchOrganization = useAction(organizationsActions.fetchOrganization);
  const fetchPaymentMethods = useAction(customersActions.fetchPaymentMethods);
  const fetchPaymentTypes = useAction(customersActions.fetchPaymentTypes);
  const removePaymentMethod = useAction(customersActions.removePaymentMethod);

  // Selectors
  const organization = useSelector(organizationSelector);
  const paymentMethods = useSelector(paymentMethodsSelector);
  const paymentTypes = useSelector(paymentTypesSelector);
  const pending = useSelector(isPendingSelector);
  const roles = useSelector(rolesSelector);

  // Constants
  const isSalesRep = isOnlySingleRole(roles, PERMISSION_TYPES.SALES_REP);
  const isOwnerOrManagerRole = useSelector(hasOwnerOrManagerRoleSelector);

  const isEditLinkVisible = isSalesRep || isOwnerOrManagerRole;

  // Effects
  useEffect(() => {
    fetchOrganization(id);
  }, [fetchOrganization, id]);

  useEffect(() => {
    if (organization?.contact_person?.id) {
      fetchPaymentMethods({
        id: organization?.contact_person?.id,
        organization_id: organization.id,
      });
      fetchPaymentTypes(organization?.contact_person?.id);
    }
  }, [organization, fetchPaymentMethods, fetchPaymentTypes]);

  // Handlers
  const onRemovePaymentMethod = methodId => {
    removePaymentMethod({
      id: methodId,
      customerId: organization?.contact_person?.id,
    });
  };

  if (pending) return <Preloader />;
  return (
    <PageWrapper>
      {isEditLinkVisible ? (
        <Breadcrumbs
          link={`/commercial-customers/${organization?.id}/edit`}
          title={`Edit ${organization?.title} #${organization?.id}`}
          breadcrumbItems={[
            {
              title: 'Back to commercial customers',
              link: '/commercial-customers/',
              withSearch: true,
            },
          ]}
        />
      ) : null}
      {!isEmpty(organization) && (
        <>
          <Row>
            <Col lg="3">
              <CardItem title="Title" values={organization.title} />
            </Col>
            <Col lg="3">
              <CardItem
                title="License Number"
                values={organization.license_number}
              />
            </Col>
            <Col lg="3">
              <CardItem
                title="Company Type"
                values={organization.company_type}
              />
            </Col>
            <Col lg="3">
              <CardItem
                title="Price Type"
                values={getNormalText(organization.price_type)}
              />
            </Col>
            {organization?.default_warehouse && (
              <Col lg="3">
                <CardItem
                  title="Default Warehouse"
                  values={organization.default_warehouse.title}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col lg="6">
              <CardItem
                title="Shipping Address"
                className={styles.mediumCard}
                values={Object.values(organization?.address).join(' ')}
              />
            </Col>
            <Col lg="6">
              <CardItem
                title="Payment Contact Information"
                className={styles.mediumCard}
                values={[
                  `${organization.contact_person.first_name} ${organization.contact_person.last_name}`,
                  organization.contact_person.email,
                  formatPhoneNumber(organization.contact_person.phone),
                ]}
              />
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col md={8}>
          <RequestPaymentTable
            organizationId={organization?.id}
            paymentMethods={paymentMethods}
            contactEmail={organization?.contact_person?.email}
            title="Request Payment Information"
          />
        </Col>
        <Col lg="12">
          {paymentMethods?.length !== 0 && paymentTypes?.length !== 0 && (
            <>
              <PaymentTypesTable paymentTypes={paymentTypes} />
              <PaymentMethodsTable
                paymentMethods={paymentMethods}
                onRemovePaymentMethod={onRemovePaymentMethod}
              />
            </>
          )}
        </Col>
      </Row>
    </PageWrapper>
  );
};

CardItem.propTypes = {
  title: PropTypes.string.isRequired,
  values: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  className: PropTypes.string,
};

export default OrganizationsShow;
