import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Row, Col, FormGroup, Label, Button, Card, CardBody } from 'reactstrap';
import { COMPANY_TYPES, HUMAN_LEADS_STATUSES } from 'constants';
import LocationSearchInput from 'components/LocationSearchInput';
import WarehousesPaginatedField from 'components/PaginatedFields/WarehousesPaginatedField';
import DateField from 'components/Fields/DateField';
import SalesRepPaginatedField from 'components/PaginatedFields/SalesRepPaginatedField';
import classNames from 'classnames';

const getAddressFieldError = (key, value) =>
  value ? `${key}: ${value[0]}` : undefined;

const CardItemWithButton = ({ title, values, children }) => (
  <Card>
    <CardBody>
      <Row>
        <Col className="text-dark">
          <h4 className="card-title">{title}</h4>
          {values.map((val, index) => (
            <div key={index}>{val}</div>
          ))}
        </Col>
        <Col className="d-flex justify-content-end">
          <div>{children}</div>
        </Col>
      </Row>
    </CardBody>
  </Card>
);

const Main = ({
  model,
  disabled,
  currentType,
  currentLeadStatus,
  setCurrentType,
  setCurrentLeadStatus,
  defaultWarehouse,
  handleDefaultWarehouseChange,
  country,
  state,
  postalCode,
  city,
  line1,
  line2,
  setCountry,
  setState,
  setPostalCode,
  setCity,
  setLine1,
  setLine2,
  submitTitle,
  fieldErrors,
  defaultWarehouseDisabled,
  isLead,
  followUpDate,
  setFollowUpDate,
  salesRep,
  setSalesRep,
  setLicenseNumber,
  licenseNumber,
  isEditing,
  businessName,
  setBusinessName,
  companyTypeError,
  setCompanyTypeError,
  businessNameError,
  licenseNumberError,
  warehouseError,
  countryError,
  stateError,
  postalCodeError,
  setPostalCodeError,
  cityError,
  line1Error,
  leadStatusError,
}) => {
  // States
  const [hasEdits, setHasEdits] = useState(false);

  // Variables
  const hasAddressFieldError =
    getAddressFieldError('Country', fieldErrors?.country) ||
    getAddressFieldError('City', fieldErrors?.city) ||
    getAddressFieldError('State', fieldErrors?.state) ||
    getAddressFieldError('Line 1', fieldErrors?.line1) ||
    getAddressFieldError('Line 2', fieldErrors?.line2) ||
    getAddressFieldError('Postal code', fieldErrors?.postalCode);

  const isButtonDisabled = () => {
    // If there are no edits, disable the button
    if (!hasEdits) return true;
    const addressPresence = country && state && postalCode && city && line1;
    const isLeadValid = currentLeadStatus && currentType && addressPresence;
    const isFormValid =
      currentType && defaultWarehouse && licenseNumber && addressPresence;

    // If the entity is a lead, check if valid
    if (isLead) return !isLeadValid;

    // Default case: if the form is valid, enable the button
    return !isFormValid;
  };

  // Functions
  const getAvailableStatuses = () => {
    if (!isEditing) {
      const { CONNECTED, ...statuses } = HUMAN_LEADS_STATUSES;
      return statuses;
    }
    return HUMAN_LEADS_STATUSES;
  };

  const handleCompanyTypeChange = selectedOption => {
    setCurrentType(selectedOption);
    setCompanyTypeError(null);
  };

  // Effects
  useEffect(() => {
    // Track if any fields have been edited
    if (
      model?.company_type !== currentType?.value ||
      model?.default_warehouse?.id !== defaultWarehouse?.value ||
      model?.license_number !== licenseNumber ||
      model?.address?.line1 !== line1 ||
      model?.address?.line2 !== line2 ||
      model?.address?.city !== city ||
      model?.address?.state !== state ||
      model?.address?.postal_code !== postalCode ||
      model?.address?.country !== country ||
      model?.title !== businessName
    ) {
      setHasEdits(true);
    } else {
      setHasEdits(false);
    }
  }, [
    currentType,
    defaultWarehouse,
    licenseNumber,
    line1,
    line2,
    city,
    state,
    postalCode,
    country,
    model,
    businessName,
  ]);

  return (
    <>
      <Row>
        <Col md="4">
          <FormGroup>
            <Label required htmlFor="business_name">
              Business Name
            </Label>
            <input
              type="text"
              name="business_name"
              placeholder="Business Name"
              value={businessName}
              onChange={e => setBusinessName(e.target.value)}
              className={classNames(
                'form-control ignore-toggle-outside',
                businessNameError && 'is-invalid'
              )}
            />
            {businessNameError && (
              <p className="text-danger mt-1">{businessNameError}</p>
            )}
          </FormGroup>
        </Col>
        {isLead && (
          <Col md={4}>
            <FormGroup>
              <Label required htmlFor="lead_status">
                Lead Status
              </Label>
              <Select
                name="lead_status"
                options={Object.entries(getAvailableStatuses()).map(
                  ([key, label]) => ({
                    label,
                    value: key,
                  })
                )}
                isDisabled={disabled}
                placeholder="Select status"
                onChange={setCurrentLeadStatus}
                value={model || currentLeadStatus ? currentLeadStatus : ''}
              />
              {leadStatusError && (
                <p className="text-danger mt-1">{leadStatusError}</p>
              )}
            </FormGroup>
          </Col>
        )}
        <Col md="4">
          <FormGroup>
            <Label required={!isLead} htmlFor="license_number">
              License Number
            </Label>
            <input
              type="text"
              name="license_number"
              placeholder="License Number"
              value={licenseNumber}
              onChange={e => setLicenseNumber(e.target.value)}
              className={classNames(
                'form-control ignore-toggle-outside',
                licenseNumberError && 'is-invalid'
              )}
            />
            {licenseNumberError && (
              <p className="text-danger mt-1">{licenseNumberError}</p>
            )}
          </FormGroup>
        </Col>
        {isLead && (
          <Col md="4">
            <Label htmlFor="sales_rep">Sales Rep</Label>
            <SalesRepPaginatedField
              onChange={({ value }) =>
                setSalesRep(prevState => ({
                  ...prevState,
                  value,
                }))
              }
              defaultValue={salesRep}
            />
          </Col>
        )}
        <Col md="4">
          <FormGroup>
            <Label required htmlFor="company_type">
              Company Type
            </Label>
            <Select
              name="company_type"
              options={COMPANY_TYPES.map(type => ({
                label: type,
                value: type,
              }))}
              placeholder="Select Type"
              onChange={handleCompanyTypeChange}
              value={currentType || ''}
              isRequired
            />
            {companyTypeError && (
              <p className="text-danger mt-1">{companyTypeError}</p>
            )}
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label required={!isLead} htmlFor="default_warehouse">
              Default Warehouse
            </Label>
            <WarehousesPaginatedField
              value={defaultWarehouse}
              onChange={handleDefaultWarehouseChange}
              name="default_warehouse"
              placeholder="Select Warehouse"
              isMulti={false}
              isDisabled={!isLead && defaultWarehouseDisabled}
            />
            {warehouseError && (
              <p className="text-danger mt-1">{warehouseError}</p>
            )}
          </FormGroup>
        </Col>
        {isLead && (
          <Col md="4">
            <DateField
              name="followUpDate"
              label="Follow-up Date"
              errorMessage="Enter Follow up Date"
              value={followUpDate}
              onChange={event => setFollowUpDate(event.target.value)}
            />
          </Col>
        )}
        <Col md="8">
          <LocationSearchInput
            model={model?.address || {}}
            country={country}
            state={state}
            postalCode={postalCode}
            city={city}
            line1={line1}
            line2={line2}
            setCountry={setCountry}
            setState={setState}
            setPostalCode={setPostalCode}
            setCity={setCity}
            setLine1={setLine1}
            setLine2={setLine2}
            error={hasAddressFieldError}
            countryError={countryError}
            stateError={stateError}
            postalCodeError={postalCodeError}
            setPostalCodeError={setPostalCodeError}
            cityError={cityError}
            line1Error={line1Error}
            isRequired
          />
        </Col>
      </Row>

      {submitTitle && (
        <Button
          disabled={isButtonDisabled()}
          color="primary"
          type="submit"
          className="mt-3"
        >
          {submitTitle}
        </Button>
      )}
    </>
  );
};

Main.propTypes = {
  model: PropTypes.object,
  disabled: PropTypes.bool,
  currentType: PropTypes.string,
  currentLeadStatus: PropTypes.string,
  setCurrentType: PropTypes.func,
  setCurrentLeadStatus: PropTypes.func,
  defaultWarehouse: PropTypes.object,
  handleDefaultWarehouseChange: PropTypes.func,
  defaultWarehouseDisabled: PropTypes.bool,
  state: PropTypes.string,
  country: PropTypes.string,
  postalCode: PropTypes.string,
  city: PropTypes.string,
  line1: PropTypes.string,
  line2: PropTypes.string,
  setCountry: PropTypes.func,
  setState: PropTypes.func,
  setPostalCode: PropTypes.func,
  setCity: PropTypes.func,
  setLine1: PropTypes.func,
  setLine2: PropTypes.func,
  submitTitle: PropTypes.string,
  fieldErrors: PropTypes.object,
  isLead: PropTypes.bool,
  followUpDate: PropTypes.string,
  setFollowUpDate: PropTypes.func,
  salesRep: PropTypes.string,
  setSalesRep: PropTypes.func,
  setLicenseNumber: PropTypes.func,
  licenseNumber: PropTypes.string,
  isEditing: PropTypes.bool,
  businessName: PropTypes.string,
  setBusinessName: PropTypes.func,
  companyTypeError: PropTypes.string,
  setCompanyTypeError: PropTypes.func,
  businessNameError: PropTypes.string,
  licenseNumberError: PropTypes.string,
  warehouseError: PropTypes.string,
  countryError: PropTypes.string,
  stateError: PropTypes.string,
  postalCodeError: PropTypes.string,
  setPostalCodeError: PropTypes.func,
  cityError: PropTypes.string,
  line1Error: PropTypes.string,
  leadStatusError: PropTypes.string,
};

CardItemWithButton.propTypes = {
  title: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node.isRequired,
};

export default Main;
