import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { actions as brandsActions } from 'models/brands/slice';
import {
  allBrandsSelector,
  isAllBrandsPendingSelector,
} from 'models/brands/selectors';

const PreloadedBrandsField = ({ value, onChange, defaultBrandID, ...prop }) => {
  // Actions
  const fetchAllBrands = useAction(brandsActions.fetchAllBrands);

  // Selectors
  const allBrands = useSelector(allBrandsSelector);
  const isLoading = useSelector(isAllBrandsPendingSelector);

  // States
  const [selectedBrand, setSelectedBrand] = useState(value || null);

  // Effects
  useEffect(() => {
    fetchAllBrands();
  }, [fetchAllBrands]);

  useEffect(() => {
    if (allBrands?.length > 0 && !selectedBrand?.value) {
      if (defaultBrandID) {
        const defaultBrand = allBrands.find(
          brand => brand.id === defaultBrandID
        );
        if (defaultBrand) {
          const formattedDefaultBrand = {
            value: defaultBrand.id,
            label: defaultBrand.title,
          };
          setSelectedBrand(formattedDefaultBrand);
          onChange(formattedDefaultBrand);
        }
      } else {
        const firstBrand = {
          value: allBrands[0].id,
          label: allBrands[0].title,
        };
        setSelectedBrand(firstBrand);
        onChange(firstBrand);
      }
    }
  }, [allBrands, selectedBrand, onChange]);

  // Variables
  const formattedBrands = allBrands?.map(brand => ({
    value: brand.id,
    label: `${brand.title}`,
    ...brand,
  }));

  // Handlers
  const handleChange = selectedOption => {
    setSelectedBrand(selectedOption);
    onChange(selectedOption);
  };

  return (
    <Select
      options={formattedBrands}
      value={selectedBrand}
      onChange={handleChange}
      placeholder="Select Winery"
      isClearable={false}
      isLoading={isLoading}
      {...prop}
    />
  );
};

PreloadedBrandsField.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  defaultBrandID: PropTypes.number,
};

export default PreloadedBrandsField;
