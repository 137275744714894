import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Button,
  FormGroup,
  Label,
  Table,
  CardBody,
  Card,
} from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import Select from 'react-select';
import { COMPANY_TYPES } from 'constants';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import TextField from 'components/Fields/TextField';
import LocationSearchInput from 'components/LocationSearchInput';
import Preloader from 'components/Preloader';
import useSelector from 'hooks/useSelector';
import useAction from 'hooks/useAction';
import { actions as sharedCustomerActions } from 'models/sharedCustomer/slice';
import {
  sharedCustomerUpdateSelector,
  isPendingSelector,
} from 'models/sharedCustomer/selectors';
import { getRedactedValues } from 'utils/getRedactedValues';
import { emailSelector } from 'models/user/selectors';
import useApiRequest from 'hooks/useApiRequest';
import { showErrorMessage } from 'utils/notification';
import { AsyncPaginate } from 'react-select-async-paginate';
import { getNormalText } from 'utils/getNormalText';
import CustomerContactPersonCard from 'components/CustomerContactPersonCard/CustomerContactPersonCard';
import styles from 'components/CustomerContactPersonCard/CustomerContactPersonCard.scss';
// import CustomerPersonCard from 'components/OrganizationForm/ContactPersonCard/ContactPersonCard';

const CustomerProfileSummary = ({ title }) => {
  // Selectors
  const sharedCustomerIsPending = useSelector(isPendingSelector);
  const currentUserEmail = useSelector(emailSelector);
  const updatedSharedCustomer = useSelector(sharedCustomerUpdateSelector);

  // Actions
  const updateSharedCustomerOrganization = useAction(
    sharedCustomerActions.updateSharedCustomerOrganization
  );

  // States
  const [businessName, setBusinessName] = useState('');
  const [licenseNumber, setLicenseNumber] = useState('');
  const [companyType, setCompanyType] = useState({ label: '', value: '' });
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [line1, setLine1] = useState('');
  const [line2, setLine2] = useState('');

  const [isEdited, setIsEdited] = useState(false);
  const [isEditTitleMode, setIsEditTitleMode] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [contactPeople, setContactPeople] = useState([
    { id: '', first_name: '', last_name: '', email: '', phone: '' },
  ]);
  const [contactPerson, setContactPerson] = useState({
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    is_payment_contact: false,
  });
  const request = useApiRequest();

  // Fetch shared customer data
  const fetchSharedCustomerOrganizations = ({ page = 1, search }) => {
    return request({
      url: `/shared_customer/organizations/?page=${page}&title_contains=${search}`,
      data: { current_email: currentUserEmail },
    });
  };

  const loadOrganizationOptions = async (search, loadedOptions, { page }) => {
    if (currentUserEmail) {
      try {
        const res = await fetchSharedCustomerOrganizations({ search, page });
        if (res.results && res.results.length === 0) {
          showErrorMessage(
            'Error!',
            'No Business organization found for customer'
          );
        } else {
          return {
            options: res.results || [],
            hasMore: res?.pagination?.next_page,
            additional: {
              page: page + 1,
            },
          };
        }
      } catch (err) {
        Object.entries(err.response?.data ?? {}).forEach(([key, value]) => {
          const preparedKey = getNormalText(key);
          showErrorMessage(
            preparedKey.toString(),
            value.toString() || 'Bad request'
          );
        });
        return {
          options: [],
          hasMore: true,
          additional: { page },
        };
      }
    }
    return {
      options: [],
    };
  };

  useEffect(() => {
    const initializeDefaultOption = async () => {
      const result = await loadOrganizationOptions('', [], { page: 1 });
      if (result?.options?.length > 0) {
        setSelectedCustomer(result.options[0]);
      }
    };
    initializeDefaultOption();
  }, [currentUserEmail]);

  // Update seleted customer when the model changes
  useEffect(() => {
    setSelectedCustomer(updatedSharedCustomer);
    setIsEditTitleMode(false);
    setContactPerson(selectedCustomer?.contact_person);
    if (selectedCustomer?.contact_people?.length > 0) {
      const filteredContacts = selectedCustomer.contact_people.filter(
        contact => contact.id !== selectedCustomer.contact_person?.id
      );
      setContactPeople(filteredContacts);
    } else {
      setContactPeople([]);
    }
  }, [updatedSharedCustomer, currentUserEmail]);

  useEffect(() => {
    setFirstName(contactPerson?.first_name || '');
    setLastName(contactPerson?.last_name || '');
    setEmail(contactPerson?.email || '');
    setPhone(contactPerson?.phone || '');
  }, [contactPerson]);

  // Update state when the model changes
  useEffect(() => {
    setBusinessName(selectedCustomer?.title || '');
    setLicenseNumber(selectedCustomer?.license_number || '');
    setCompanyType({
      label: selectedCustomer?.company_type || '',
      value: selectedCustomer?.company_type || '',
    });
    setFirstName(selectedCustomer?.contact_person?.first_name || '');
    setLastName(selectedCustomer?.contact_person?.last_name || '');
    setEmail(selectedCustomer?.contact_person?.email || '');
    setPhone(selectedCustomer?.contact_person?.phone || '');
    setCountry(selectedCustomer?.address?.country || '');
    setState(selectedCustomer?.address?.state || '');
    setPostalCode(selectedCustomer?.address?.postal_code || '');
    setCity(selectedCustomer?.address?.city || '');
    setLine1(selectedCustomer?.address?.line1 || '');
    setLine2(selectedCustomer?.address?.line2 || '');
    setContactPerson(selectedCustomer?.contact_person);
    if (selectedCustomer?.contact_people?.length > 0) {
      const filteredContacts = selectedCustomer.contact_people.filter(
        contact => contact.id !== selectedCustomer.contact_person?.id
      );
      setContactPeople(filteredContacts);
    } else {
      setContactPeople([]);
    }
  }, [selectedCustomer]);

  // Effect to detect form edits
  useEffect(() => {
    if (selectedCustomer) {
      const isFormEdited =
        businessName !== (selectedCustomer?.title || '') ||
        licenseNumber !== (selectedCustomer?.license_number || '') ||
        companyType?.value !== (selectedCustomer?.company_type || '') ||
        firstName !== (selectedCustomer?.contact_person?.first_name || '') ||
        lastName !== (selectedCustomer?.contact_person?.last_name || '') ||
        email !== (selectedCustomer?.contact_person?.email || '') ||
        phone !== (selectedCustomer?.contact_person?.phone || '') ||
        country !== (selectedCustomer?.address?.country || '') ||
        state !== (selectedCustomer?.address?.state || '') ||
        city !== (selectedCustomer?.address?.city || '') ||
        line1 !== (selectedCustomer?.address?.line1 || '') ||
        line2 !== (selectedCustomer?.address?.line2 || '') ||
        postalCode !== (selectedCustomer?.address?.postal_code || '');
      setIsEdited(isFormEdited);
    }
  }, [
    businessName,
    licenseNumber,
    companyType,
    country,
    state,
    city,
    line1,
    line2,
    postalCode,
    firstName,
    lastName,
    email,
    phone,
    selectedCustomer,
  ]);

  // Handler
  const handleSubmit = async (event, values) => {
    const sharedCustomer = {
      ...selectedCustomer,
      contact_person_first_name: selectedCustomer?.contact_person?.first_name,
      contact_person_last_name: selectedCustomer?.contact_person?.last_name,
      contact_person_email: selectedCustomer?.contact_person?.email,
      contact_person_phone: selectedCustomer?.contact_person?.phone,
    };
    if (values.title || !isEditTitleMode) {
      // Handle form submission logic here
      const submittedValues = {
        ...values,
        city,
        country,
        line1,
        line2,
        postal_code: postalCode,
        state,
        company_type: companyType?.value,
        license_number: licenseNumber,
        contact_person_first_name: contactPerson.first_name,
        contact_person_last_name: contactPerson.last_name,
        contact_person_email: contactPerson.email,
        contact_person_phone: contactPerson.phone,
        current_email: currentUserEmail,
        title: businessName,
      };
      const redactedValues = getRedactedValues(sharedCustomer, submittedValues);
      updateSharedCustomerOrganization({
        id: selectedCustomer?.id,
        ...redactedValues,
      });
    }
  };

  // Handler for Edit Business Name
  const handleEditClick = () => setIsEditTitleMode(true);
  // Handler for Cancel
  const handleCancelClick = () => {
    setBusinessName(selectedCustomer.title);
    setIsEditTitleMode(false);
  };
  if (sharedCustomerIsPending) return <Preloader />;

  const handleChangeContact = updatedContact => {
    if (contactPerson.id === updatedContact.id) {
      setContactPerson(prevPerson => ({
        ...prevPerson,
        ...updatedContact,
      }));
    }
  };

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row>
        {!isEditTitleMode && (
          <Col md={4}>
            <Label htmlFor="organization">Select Business</Label>
          </Col>
        )}
      </Row>
      <Row>
        {!isEditTitleMode && (
          <Col className="mb-3" md={4}>
            <AsyncPaginate
              name="organization_id"
              debounceTimeout={1000}
              defaultValue={selectedCustomer}
              value={selectedCustomer}
              loadOptions={loadOrganizationOptions}
              onChange={data => {
                setSelectedCustomer(data);
              }}
              additional={{
                page: 1,
              }}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.title}
              menuPlacement="bottom"
              maxMenuHeight={190}
            />
          </Col>
        )}
        {!isEditTitleMode && selectedCustomer?.id && (
          <Col md={2}>
            <Button color="secondary" onClick={handleEditClick}>
              Edit Business Name
            </Button>
          </Col>
        )}
      </Row>
      <AvForm onValidSubmit={handleSubmit}>
        <Row>
          {isEditTitleMode && (
            <Col md={4}>
              <TextField
                label="Business Name"
                name="title"
                placeholder="Business Name"
                isRequired
                value={businessName}
                onChange={e => setBusinessName(e.target.value)}
              />
            </Col>
          )}
          {isEditTitleMode && (
            <Col md={4} className="d-flex justify-content-left mt-4">
              <Button
                className="mt-2 mb-3"
                color="primary"
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
            </Col>
          )}
        </Row>
        <Row>
          <Col md={4}>
            <TextField
              placeholder="License Number"
              isRequired
              label="License Number"
              name="license_number"
              value={licenseNumber}
              onChange={e => setLicenseNumber(e.target.value)}
            />
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label htmlFor="company_type">Company Type</Label>
              <Select
                name="company_type"
                options={COMPANY_TYPES.map(type => ({
                  label: type,
                  value: type,
                }))}
                placeholder="Select Type"
                value={companyType}
                onChange={selectedOption => setCompanyType(selectedOption)}
              />
            </FormGroup>
          </Col>
          <Col md={8}>
            <LocationSearchInput
              model={selectedCustomer}
              country={country}
              state={state}
              postalCode={postalCode}
              city={city}
              line1={line1}
              line2={line2}
              setCountry={setCountry}
              setState={setState}
              setPostalCode={setPostalCode}
              setCity={setCity}
              setLine1={setLine1}
              setLine2={setLine2}
              isRequired
            />
          </Col>
        </Row>
        {contactPerson && (
          <div>
            <Row mt={2}>
              <Col md={12}>
                <div className="font-size-18 mb-2 font-weight-bold">
                  My Contacts:
                </div>
              </Col>
            </Row>
            <CustomerContactPersonCard
              id={contactPerson.id}
              defaultValue={contactPerson}
              onChange={updatedContact => handleChangeContact(updatedContact)}
            />
          </div>
        )}
        {contactPeople?.length > 0 && (
          <div>
            <Row mt={2}>
              <Col md={12}>
                <div className="font-size-18 mb-2 font-weight-bold">
                  Other Contacts:
                </div>
              </Col>
            </Row>
            <div>
              <Card className={styles.cardContainer}>
                <CardBody>
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <Table id="other-contacts" bordered responsive>
                      <thead>
                        <tr>
                          <th data-priority="1">First Name</th>
                          <th data-priority="1">Last Name</th>
                          <th data-priority="1">Email</th>
                          <th data-priority="1">Phone</th>
                        </tr>
                      </thead>
                      <tbody>
                        {contactPeople.map(contact => (
                          <tr>
                            <td>{contact.first_name}</td>
                            <td>{contact.last_name}</td>
                            <td>{contact.email}</td>
                            <td>{contact.phone}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        )}
        <Row>
          <Col>
            <Button color="primary" type="submit" disabled={!isEdited}>
              Save
            </Button>
          </Col>
        </Row>
      </AvForm>
    </PageWrapper>
  );
};

CustomerProfileSummary.propTypes = {
  title: PropTypes.string,
};

export default CustomerProfileSummary;
