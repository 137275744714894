import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ContactPersonCard from '../ContactPersonCard/ContactPersonCard';
import {
  Card,
  CardBody,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import styles from './ContactTab.scss';
import classNames from 'classnames';

const ContactTab = ({
  contactPeople,
  setContactPeople,
  handleAddContact,
  hasEmptyValuesInContactPeople,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [contactToDelete, setContactToDelete] = useState(null);
  const [isSingleContact, setIsSingleContact] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedContactIndex, setSelectedContactIndex] = useState(null);
  const [isMakePaymentAndDelete, setIsMakePaymentAndDelete] = useState(false);

  const handleChangeContact = (index, updatedContact) => {
    setContactPeople(prevState => {
      const currentContact = prevState[index];
      const hasChanges = Object.keys(updatedContact).some(
        key => currentContact[key] !== updatedContact[key]
      );

      if (hasChanges) {
        const newState = [...prevState];
        newState[index] = { ...currentContact, ...updatedContact };
        return newState;
      }

      return prevState;
    });
  };

  const handleDeleteContact = index => {
    if (contactPeople.length === 1) {
      // Single contact case
      setIsSingleContact(true);
      setShowModal(true);
    } else if (contactPeople[index].is_payment_contact) {
      // Payment contact case
      setContactToDelete(index);
      setShowModal(true);
    } else {
      deleteContact(index);
    }
  };

  const deleteContact = index => {
    setContactPeople(prevState => {
      const newState = prevState.map(contact => ({ ...contact })); // Create a shallow copy of each contact
      newState.splice(index, 1);

      if (newState.length === 1) {
        newState[0] = { ...newState[0], is_payment_contact: true }; // Update is_payment_contact immutably
      }

      if (newState.length === 0) {
        // Reset to a default empty contact
        newState.push({
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          is_payment_contact: false,
        });
      }

      return newState;
    });

    setShowModal(false); // Close the modal
    setContactToDelete(null); // Reset the contact to delete
    setIsSingleContact(false); // Reset the single contact flag

    setShowModal(false);
    setContactToDelete(null);
    setIsSingleContact(false);
  };

  const handleConfirmPaymentContact = () => {
    if (isMakePaymentAndDelete) {
      setContactPeople(prevState => {
        const updatedContacts = prevState.map(contact => ({ ...contact })); // Create a shallow copy of each contact
        updatedContacts.forEach((contact, index) => {
          contact.is_payment_contact = index === selectedContactIndex; // Update immutably
        });
        updatedContacts.splice(contactToDelete, 1); // Remove the contact to delete
        if (updatedContacts.length === 1) {
          updatedContacts[0] = {
            ...updatedContacts[0],
            is_payment_contact: true,
          }; // Ensure the remaining contact is payment contact
        }
        if (updatedContacts.length === 0) {
          updatedContacts.push({
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            is_payment_contact: false,
          });
        }
        return updatedContacts;
      });
    } else {
      setContactPeople(prevState =>
        prevState.map((contact, i) => ({
          ...contact,
          is_payment_contact: i === selectedContactIndex,
        }))
      );
    }
    setShowConfirmationModal(false);
    setShowModal(false);
    setSelectedContactIndex(null);
    setContactToDelete(null);
    setIsSingleContact(false);
    setIsMakePaymentAndDelete(false);
  };

  const handleCancelConfirmation = () => {
    setShowConfirmationModal(false);
    setSelectedContactIndex(null);
  };

  const handleMakePaymentContact = index => {
    setSelectedContactIndex(index);
    setShowConfirmationModal(true);
  };

  const makePaymentContactAndDelete = (
    newPaymentContactIndex,
    contactToDeleteIndex
  ) => {
    setIsMakePaymentAndDelete(true);
    setSelectedContactIndex(newPaymentContactIndex);
    setContactToDelete(contactToDeleteIndex);
    setShowConfirmationModal(true);
  };

  useEffect(() => {
    if (contactPeople.length === 1 && !contactPeople[0].is_payment_contact) {
      setContactPeople(prevState => {
        if (!prevState[0].is_payment_contact) {
          return prevState.map((contact, index) => ({
            ...contact,
            is_payment_contact: index === 0,
          }));
        }
        return prevState;
      });
    }
  }, [contactPeople, setContactPeople]);

  return (
    <>
      <Card>
        <CardBody className={styles.table}>
          <Table bordered responsive>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Role</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {contactPeople.map((contact, index) => (
                <ContactPersonCard
                  key={index}
                  id={index}
                  defaultValue={contact}
                  onChange={updatedContact =>
                    handleChangeContact(index, updatedContact)
                  }
                  onDelete={() => handleDeleteContact(index)}
                  makePaymentContact={i => handleMakePaymentContact(i)}
                  disabled={false}
                />
              ))}

              <tr>
                <td colSpan={6}>
                  <Button
                    onClick={handleAddContact}
                    type="button"
                    className={classNames(styles.button, 'mr-3')}
                    color="success"
                    disabled={hasEmptyValuesInContactPeople(contactPeople)}
                  >
                    + Add Contact Person
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>

      {/* Modal for handling deletion */}
      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>
          {isSingleContact
            ? 'Cannot Delete Payment Contact'
            : 'Select Another Payment Contact'}
        </ModalHeader>
        <ModalBody>
          {isSingleContact ? (
            <p>
              This is the only contact person for this Commercial Customer and
              is marked as the payment contact. Please add a new contact person
              before deleting this one.
            </p>
          ) : (
            <>
              <p>
                You are trying to delete a contact marked as the payment
                contact. Please select another contact as the payment contact
                before proceeding.
              </p>
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {contactPeople.map((contact, index) => {
                    if (index === contactToDelete) {
                      return null; // Exclude the contact being deleted
                    }
                    return (
                      <tr key={index}>
                        <td>{contact.first_name}</td>
                        <td>{contact.last_name}</td>
                        <td>
                          <Button
                            color="primary"
                            onClick={() =>
                              makePaymentContactAndDelete(
                                index,
                                contactToDelete
                              )
                            }
                          >
                            Make Payment Contact
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={showConfirmationModal}
        toggle={() => setShowConfirmationModal(false)}
      >
        <ModalHeader>
          Are you sure you want to replace the contact person?
        </ModalHeader>
        <ModalBody>
          Changing the contact person will remove all the current Credit Cards
          associated with this business?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleCancelConfirmation}>
            Close
          </Button>
          <Button color="primary" onClick={handleConfirmPaymentContact}>
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

ContactTab.propTypes = {
  contactPeople: PropTypes.arrayOf(
    PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      is_payment_contact: PropTypes.bool,
    })
  ).isRequired,
  setContactPeople: PropTypes.func.isRequired,
  handleAddContact: PropTypes.func.isRequired,
  hasEmptyValuesInContactPeople: PropTypes.func.isRequired,
};

export default ContactTab;
