import React from 'react';
import ActionDropdown from 'components/ActionDropdown';
import PropTypes from 'prop-types';

// Constants
const DropdownItemId = Object.freeze({
  DELETE: 'delete',
  IS_PAYMENT_CONTACT: 'is_payment_contact',
});

const ContactEditActionDropdown = ({
  onDelete,
  handlePaymentContact,
  isPaymentContact,
}) => {
  // Handlers
  const onItemClickHandler = item => {
    const itemHandlers = {
      [DropdownItemId.DELETE]: onDelete,
      [DropdownItemId.IS_PAYMENT_CONTACT]: handlePaymentContact,
    };

    if (item.id in itemHandlers) {
      const handler = itemHandlers[item.id];
      handler?.();
    }
  };

  // Dropdown items
  const dropdownItem = [
    {
      id: DropdownItemId.DELETE,
      icon: <i className="mdi mdi-delete font-size-18 mr-2" />,
      label: 'Delete',
    },
    // Include "Make Payment Contact" only if this contact is not already the payment contact
    !isPaymentContact && {
      id: DropdownItemId.IS_PAYMENT_CONTACT,
      icon: <i className="mdi mdi-account-check font-size-18 mr-2" />,
      label: 'Make Payment Contact',
    },
  ].filter(Boolean); // Remove `false` items if the condition above fails

  return (
    <ActionDropdown
      items={dropdownItem}
      onItemClick={onItemClickHandler}
      right
    />
  );
};

ContactEditActionDropdown.propTypes = {
  onDelete: PropTypes.func,
  handlePaymentContact: PropTypes.func,
  isPaymentContact: PropTypes.bool,
};

export default ContactEditActionDropdown;
