import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';

import PageWrapper from 'components/PageWrapper';
import useAction from 'hooks/useAction';
import { actions as userActions } from 'models/user/slice';
import { Link } from 'react-router-dom';
import CheckboxField from 'components/Fields/CheckboxField';
import EmailField from 'components/Fields/EmailField';
import PasswordField from 'components/Fields/PasswordField';
import config from 'config';
import useSelector from 'hooks/useSelector';
import { isOnlySharedCustomerSelector } from 'models/user/selectors';
import useQuery from 'hooks/useQuery';

const Login = ({ title }) => {
  // Selectors
  const onlySharedCustomer = useSelector(isOnlySharedCustomerSelector);

  // States
  const [email, setEmail] = useState('');

  // Actions
  const login = useAction(userActions.login);

  // Handlers
  const handleSubmit = useCallback(
    (event, values) => {
      login(values);
    },
    [login]
  );

  // Constants
  const isIPad =
    /iPad/.test(navigator.platform) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

  const { dashboardUrl } = config;

  const query = useQuery();

  const isCustomer = parseInt(query.get('customer'), 10) === 1;

  const welcomeMessage = isCustomer
    ? 'Sign in to view your payment methods.'
    : 'Sign in to view admin portal.';

  if (query.has('brand_id')) {
    localStorage.setItem('lastBrandID', Number(query.get('brand_id')));
  }

  return (
    <PageWrapper title={title}>
      <h4 className="font-size-18 mt-4 text-center">Welcome Back!</h4>
      <p className="text-muted mb-4 text-center">{welcomeMessage}</p>
      <AvForm className="form-horizontal pt-4" onValidSubmit={handleSubmit}>
        <EmailField
          onChange={event => setEmail(event.target.value.toLowerCase())}
          placeholder="Enter email"
          className="auth-form-group-custom mb-4"
          name="email"
          label="Email"
          isRequired
          icon={<i className="ri-mail-line auti-custom-input-icon" />}
        />
        <PasswordField
          icon={<i className="ri-lock-2-line auti-custom-input-icon" />}
          placeholder="Enter password"
          name="password"
          isRequired
          label="Password"
          className="auth-form-group-custom mb-4"
        />

        <div className="custom-control custom-checkbox">
          <CheckboxField
            className="d-flex flex-row-reverse justify-content-end"
            label="Remember me"
            value
            name="remember_me"
          />
        </div>

        <div className="mt-4 text-center">
          <Button
            color="primary"
            className="w-md waves-effect waves-light stretch-wd"
            type="submit"
          >
            Log In
          </Button>
        </div>

        <div className="mt-3 text-center">
          <Link to={`/recover-password?email=${encodeURIComponent(email)}`}>
            Forgot Password?
          </Link>
        </div>
      </AvForm>
      {!isIPad && !onlySharedCustomer && (
        <>
          <hr />
          <div className="mt-4 text-center">
            <Button
              color="secondary"
              className="w-md waves-effect waves-light stretch-wd"
              onClick={() => {
                window.location.href = dashboardUrl;
              }}
            >
              Go to POS
            </Button>
          </div>
        </>
      )}
    </PageWrapper>
  );
};

Login.propTypes = {
  title: PropTypes.string,
};

export default Login;
