import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import useAction from 'hooks/useAction';
import Preloader from 'components/Preloader';
import useSelector from 'hooks/useSelector';

import SubscriptionCancelPopup from 'components/Popup/SubscriptionCancelPopup';
import AddressPopup from 'components/Popup/AddressPopup';

import { actions as subscriptionsActions } from 'models/subscriptions/slice';
import { actions as customersActions } from 'models/customers/slice';
import { actions as ordersActions } from 'models/orders/slice';
import {
  customerSelector,
  isPendingSelector,
  wineClubMemberPendingSelector,
  isPendingNotesSelector,
  paymentMethodsSelector,
} from 'models/customers/selectors';

import { ordersSelector, paginationSelector } from 'models/orders/selectors';

import { Row, Col } from 'reactstrap';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';

import Main from './Main';
import Header from './Header';
import InformationWineDirect from './InformationWineDirect';
import { formatDate } from 'utils/formatDate';
import formatPrice from 'utils/formatPrice';
import CancelReasonPopup from 'components/Popup/CancelReasonPopup';

const getWineDirectData = wineclubs =>
  wineclubs.filter(club => club.wine_direct_imported_data);

const dateFields = ['birthdate', 'cancel_date'];
const priceFields = ['lifetime_value'];
const isDate = /\d{4}-\d{2}-\d{2}/;

const getImportedData = importedData =>
  Object.entries(importedData).map(([key, value]) => {
    const getFormatForFieldWithoutTime = dateFields.includes(key)
      ? 'yyyy-MM-dd'
      : 'yyyy-MM-dd, h:mm aaa';

    const dateValue =
      isDate.test(value) && formatDate(value, getFormatForFieldWithoutTime);
    const priceValue = priceFields.includes(key) && formatPrice(value);

    return {
      key,
      value: dateValue || priceValue || value,
    };
  });

const CustomersShow = ({ title }) => {
  const { id } = useParams();
  const fetchCustomer = useAction(customersActions.fetchCustomer);
  const fetchOrders = useAction(ordersActions.fetchOrders);
  const fetchPaymentMethods = useAction(customersActions.fetchPaymentMethods);
  const removePaymentMethod = useAction(customersActions.removePaymentMethod);
  const toggleWineClubMemberActivity = useAction(
    customersActions.toggleWineClubMemberActivity
  );
  const pending = useSelector(isPendingSelector);
  const wineClubMemberPending = useSelector(wineClubMemberPendingSelector);
  const customer = useSelector(customerSelector);
  const pendingNotes = useSelector(isPendingNotesSelector);
  const orders = useSelector(ordersSelector);
  const ordersPagination = useSelector(paginationSelector);
  const paymentMethods = useSelector(paymentMethodsSelector);

  // subscriptions
  const cancelSubscription = useAction(subscriptionsActions.cancelSubscription);
  const createSubscription = useAction(subscriptionsActions.createSubscription);
  const [activeSubscriptionModal, setActiveSubscriptionModal] = useState(null);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState();
  const [
    isCancelSubscriptionModalVisible,
    setIsCancelSubscriptionModalVisible,
  ] = useState(false);
  const hideCancelSubscriptionModal = () => {
    setIsCancelSubscriptionModalVisible(false);
    setSelectedSubscriptionId(undefined);
  };
  const handleCancelSubscriptionPress = subscriptionId => {
    setIsCancelSubscriptionModalVisible(true);
    setSelectedSubscriptionId(subscriptionId);
  };

  const handleSubmitCancelSubscriptionForm = reason => {
    cancelSubscription({ id: selectedSubscriptionId, cancel_reason: reason });
    hideCancelSubscriptionModal();
  };

  const handleActiveSubscription = (tierId, wineclubId) => {
    setActiveSubscriptionModal({
      id: tierId,
      customer,
      address_info: customer?.customer_profile,
      wineclub_id: wineclubId,
    });
  };

  const handleSubmitActiveSubscription = data => {
    const { address, is_gift, gift_note, wineclub_id } = data;
    createSubscription({
      ...(address ?? {}),
      is_gift,
      gift_note,
      customer_id: activeSubscriptionModal?.customer?.id,
      tier_id: activeSubscriptionModal?.id,
      wineclub_id,
    });
    setActiveSubscriptionModal(null);
  };

  const [isCancelReasonModalVisible, setIsCancelReasonModalVisible] = useState(
    false
  );

  const [cancelReasonTitle, setCancelReasonTitle] = useState('');

  const hideCancelReasonModal = () => {
    setIsCancelReasonModalVisible(false);
    setCancelReasonTitle('');
  };
  const showCancelReasonModal = cancelReasonTitle_ => {
    setCancelReasonTitle(cancelReasonTitle_);
    setIsCancelReasonModalVisible(true);
  };

  useEffect(() => {
    fetchCustomer(id);
  }, [fetchCustomer, id, pendingNotes]);

  useEffect(() => {
    if (id) fetchPaymentMethods({ id });
  }, [fetchPaymentMethods, id]);

  useEffect(() => {
    if (id)
      fetchOrders({
        customer_id: id,
        sort_field: 'paid_at',
        setQuery: false,
      });
  }, [fetchOrders, id]);

  const onOrdersPageChange = page => {
    fetchOrders({
      customer_id: id,
      sort_field: 'paid_at',
      page,
      setQuery: false,
    });
  };

  const onRemovePaymentMethod = methodId => {
    removePaymentMethod({ id: methodId, customerId: id });
  };

  const handleWineClubMemberAction = memberId => {
    toggleWineClubMemberActivity(memberId);
  };

  if (pending) return <Preloader />;

  return (
    <PageWrapper title={title}>
      {isCancelReasonModalVisible && (
        <CancelReasonPopup
          title={cancelReasonTitle}
          onClose={hideCancelReasonModal}
        />
      )}
      {isCancelSubscriptionModalVisible && (
        <SubscriptionCancelPopup
          onClose={hideCancelSubscriptionModal}
          onSubmit={handleSubmitCancelSubscriptionForm}
        />
      )}
      {activeSubscriptionModal && (
        <AddressPopup
          data={activeSubscriptionModal}
          onClose={() => setActiveSubscriptionModal(null)}
          onSubmit={handleSubmitActiveSubscription}
        />
      )}
      <Breadcrumbs
        link={`/wineclub-customers/${id}/edit`}
        title={`Edit ${customer?.first_name || ''} ${customer?.last_name ||
          ''} #${customer?.id}`}
        breadcrumbItems={[
          {
            title: 'Back to customers',
            link: '/wineclub-customers/',
            withSearch: true,
          },
        ]}
      />
      {customer ? (
        <>
          <Row>
            <Col lg="12">
              <Header
                customer={customer}
                onWineClubMemberAction={handleWineClubMemberAction}
                wineClubMemberPending={wineClubMemberPending}
                showCancelReasonModal={showCancelReasonModal}
                hideCancelReasonModal={hideCancelReasonModal}
                onCancelSubscription={handleCancelSubscriptionPress}
                onActivateSubscription={handleActiveSubscription}
              />
            </Col>
          </Row>
          {getWineDirectData(customer.wine_club_members).map(club => (
            <Row>
              <Col lg="12">
                <InformationWineDirect
                  title={club.wineclub_title}
                  key={club.wineclub_id}
                  importeData={getImportedData(club.wine_direct_imported_data)}
                />
              </Col>
            </Row>
          ))}
          <Row>
            <Col md="12">
              <Main
                orders={orders}
                ordersPagination={ordersPagination}
                onOrdersPageChange={onOrdersPageChange}
                products={customer?.purchased_products}
                notes={customer?.notes}
                paymentMethods={paymentMethods}
                onRemovePaymentMethod={onRemovePaymentMethod}
              />
            </Col>
          </Row>
        </>
      ) : (
        <p>Something went wrong while getting customer information</p>
      )}
    </PageWrapper>
  );
};

CustomersShow.propTypes = {
  title: PropTypes.string,
};

export default CustomersShow;
