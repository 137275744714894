import React, { useState, useEffect } from 'react';
import TextField from 'components/Fields/TextField/TextField';
import PhoneField from 'components/Fields/PhoneField/PhoneField';
import EmailField from 'components/Fields/EmailField/EmailField';
import PropTypes from 'prop-types';
import { Col, Row, Label } from 'reactstrap';
import styles from 'components/CustomerContactPersonCard/CustomerContactPersonCard.scss';

const CustomerContactPersonCard = ({ id, onChange, defaultValue }) => {
  const [contact, setContact] = useState(defaultValue);
  const [errors, setErrors] = useState({}); // Track validation errors
  const validateField = (field, value) => {
    if (!value) {
      return 'This field is required';
    }
    if (field === 'email' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      return 'Invalid email address';
    }
    if (field === 'phone') {
      const newValue = value?.slice(1);
      if (newValue.length < 10) return 'The filed require 11 digit number';
    }
    return '';
  };

  useEffect(() => {
    // Update local state only when `defaultValue` actually changes
    if (JSON.stringify(contact) !== JSON.stringify(defaultValue)) {
      setContact(defaultValue);
    }
  }, [defaultValue]);

  const handleInputChange = (field, value) => {
    const error = validateField(field, value);
    setErrors(prevErrors => ({ ...prevErrors, [field]: error }));
    onChange({ ...contact, [field]: value });
  };

  return (
    <Row mb={3}>
      <Col md={2}>
        <Label htmlFor={`contact_people.${id}.first_name`}>First Name</Label>
        <TextField
          name={`contact_people.${id}.first_name`}
          placeholder="First Name"
          isRequired
          value={contact.first_name}
          onChange={({ target }) =>
            handleInputChange('first_name', target.value)
          }
          disabled={!contact.is_payment_contact}
        />
        {errors.first_name && (
          <span className={styles.errorMessage}>{errors.first_name}</span>
        )}
      </Col>
      <Col md={2}>
        <Label htmlFor={`contact_people.${id}.last_name`}>Last Name</Label>
        <TextField
          name={`contact_people.${id}.last_name`}
          placeholder="Last Name"
          isRequired
          value={contact.last_name}
          onChange={({ target }) =>
            handleInputChange('last_name', target.value)
          }
          disabled={!contact.is_payment_contact}
        />
        {errors.last_name && (
          <span className={styles.errorMessage}>{errors.last_name}</span>
        )}
      </Col>
      <Col md={2}>
        <Label htmlFor={`contact_people.${id}.email`}>Email</Label>
        <EmailField
          name={`contact_people.${id}.email`}
          placeholder="Email"
          isRequired
          value={contact.email}
          onChange={({ target }) => handleInputChange('email', target.value)}
          disabled={!contact.is_payment_contact}
        />
        {errors.email && (
          <span className={styles.errorMessage}>{errors.email}</span>
        )}
      </Col>
      <Col md={2}>
        <Label htmlFor={`contact_people.${id}.phone`}>Phone</Label>
        <PhoneField
          name={`contact_people.${id}.phone`}
          placeholder="Phone"
          isRequired
          value={contact.phone}
          onChange={value => handleInputChange('phone', String(value || ''))}
          disabled={!contact.is_payment_contact}
        />
        {errors.phone && (
          <span className={styles.errorMessage}>{errors.phone}</span>
        )}
      </Col>
    </Row>
  );
};

CustomerContactPersonCard.propTypes = {
  id: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    is_payment_contact: PropTypes.bool,
  }).isRequired,
};

export default CustomerContactPersonCard;
