import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';
import { CUSTOMER_FORM_STEPS } from 'components/CustomerForm/constants';

import { actions as subscriptionsActions } from 'models/subscriptions/slice';

const changeCustomersWithId = customers => {
  const result = {};
  customers?.forEach(i => {
    if (i.id === 0) {
      result[i.email] = i;
    } else {
      result[i.id] = i;
    }
  });
  return result;
};

const customersSlice = createSlice({
  name: 'customers',
  initialState: {
    isPending: false,
    wineClubMemberPending: false,
    paymentPending: false,
    notesPending: false,
    step: CUSTOMER_FORM_STEPS.MAIN,
    customers: {},
    customersIds: {},
    customer: null,
    abstractCustomers: {},
    paymentMethods: [],
    paymentTypes: [],
    paymentTypesAvailable: [],
    intent: {},
    pagination: {},
    customersInvited: false,
    provinces: [],
    isCreatedCustomer: false,
    intentFailure: false,
  },
  reducers: {
    fetchCustomers: state => {
      state.isPending = true;
    },
    fetchCustomersSuccess(state, { payload }) {
      const {
        results,
        pagination,
        workspace_states,
        customers_ids,
      } = payload.data;
      state.isPending = false;
      state.customers = changeCustomersWithId(results);
      state.customersIds = customers_ids;
      state.pagination = pagination;
      state.provinces = workspace_states?.filter(province => !!province);
    },
    fetchCustomersFailure: state => {
      state.isPending = false;
    },
    fetchCustomer: state => {
      state.isPending = true;
    },
    fetchCustomerSuccess(state, { payload }) {
      state.isPending = false;
      state.customer = payload.data;
    },
    fetchCustomerFailure: state => {
      state.isPending = false;
    },
    inviteCustomers: state => {
      state.isPending = true;
      state.customersInvited = false;
    },
    inviteCustomersSuccess: (state, { payload }) => {
      const { data } = payload;
      state.isPending = false;
      state.customersInvited = true;
      data.ids.map(
        // eslint-disable-next-line no-return-assign
        id =>
          (state.customers[id].last_email = {
            sent_at: data.sent_at,
            template: data.template_title,
          })
      );
    },
    inviteCustomersFailure: state => {
      state.isPending = false;
    },
    wineNotificationCustomers: state => {
      state.isPending = true;
    },
    wineNotificationCustomersSuccess: state => {
      state.isPending = false;
    },
    wineNotificationCustomersFailure: state => {
      state.isPending = false;
    },
    createCustomer: state => {
      state.isPending = true;
    },
    createCustomerSuccess: (state, { payload }) => {
      state.customer = payload.data;
      state.isPending = false;
      state.step = CUSTOMER_FORM_STEPS.PAYMENT;
    },
    createCustomerFailure: state => {
      state.isPending = false;
    },
    editCustomer: state => {
      state.isPending = true;
    },
    editCustomerSuccess: (state, { payload }) => {
      state.customer = payload.data;
      state.isPending = false;
    },
    editCustomerFailure: state => {
      state.isPending = false;
    },
    setupIntent: state => {
      state.paymentPending = false;
    },
    addCustomerNote: state => {
      state.notesPending = true;
    },
    addCustomerNoteSuccess: state => {
      state.notesPending = false;
    },
    addCustomerNoteFailure: state => {
      state.notesPending = false;
    },
    setupIntentSuccess(state, { payload }) {
      state.paymentPending = false;
      state.intentFailure = false;
      state.intent = payload.data;
    },
    setupIntentFailure: state => {
      state.paymentPending = false;
      state.intentFailure = true;
    },
    sendPaymentMethod: state => {
      state.isPending = true;
    },
    sendPaymentMethodSuccess(state, { payload }) {
      state.isPending = false;
      state.paymentMethods.push(payload.data);
    },
    sendPaymentMethodFailure: state => {
      state.isPending = false;
    },
    fetchPaymentTypesAvailable: state => {
      state.paymentPending = true;
    },
    fetchPaymentTypesAvailableSuccess(state, { payload }) {
      state.paymentPending = false;
      state.paymentTypesAvailable = payload.data.results;
    },
    fetchPaymentTypesAvailableFailure: state => {
      state.paymentPending = false;
    },
    fetchPaymentTypes: state => {
      state.paymentPending = true;
    },
    fetchPaymentTypesSuccess(state, { payload }) {
      state.paymentPending = false;
      state.paymentTypes = payload.data.results;
    },
    fetchPaymentTypesFailure: state => {
      state.paymentPending = false;
    },
    createPaymentType: state => {
      state.paymentPending = true;
    },
    createPaymentTypeSuccess(state, { payload }) {
      const { data, inResponseTo } = payload;
      const paymentTypes = state.paymentTypes.map(type => ({
        ...type,
        is_default: false,
      }));
      paymentTypes.push(data);
      state.paymentPending = false;
      state.paymentTypes = paymentTypes;
      state.paymentTypesAvailable = state.paymentTypesAvailable.filter(
        type => type !== inResponseTo.type
      );
    },
    createPaymentTypesFailure: state => {
      state.paymentPending = false;
    },
    removePaymentType: state => {
      state.paymentPending = true;
    },
    removePaymentTypeSuccess(state, { payload }) {
      state.paymentPending = false;
      const { id, type } = payload.inResponseTo;
      state.paymentTypes = state.paymentTypes.filter(item => item.id !== id);
      state.paymentTypesAvailable = [type, ...state.paymentTypesAvailable];
    },
    removePaymentTypesFailure: state => {
      state.paymentPending = false;
    },
    makeDefaultPaymentType: state => {
      state.paymentPending = true;
    },
    makeDefaultPaymentTypeSuccess: (state, { payload }) => {
      state.paymentPending = false;
      const { id } = payload.inResponseTo;
      state.paymentTypes = state.paymentTypes.map(item => ({
        ...item,
        is_default: item.id === id,
      }));
    },
    makeDefaultPaymentTypeFailure: state => {
      state.paymentPending = false;
    },
    fetchPaymentMethods: state => {
      state.paymentPending = true;
    },
    fetchPaymentMethodsSuccess(state, { payload }) {
      state.paymentPending = false;
      state.paymentMethods = payload.data.results;
    },
    clearPaymentMethods(state) {
      state.paymentMethods = [];
      state.intentFailure = false;
    },
    fetchPaymentMethodsFailure: state => {
      state.paymentPending = false;
    },
    removePaymentMethod: state => {
      state.isPending = true;
    },
    removePaymentMethodSuccess: (state, { payload }) => {
      state.isPending = false;
      const { id } = payload.inResponseTo;
      state.paymentMethods = state.paymentMethods.filter(
        method => method.id !== id
      );
    },
    removePaymentMethodFailure: state => {
      state.isPending = false;
    },
    makeDefaultPaymentMethod: state => {
      state.isPending = true;
    },
    makeDefaultPaymentMethodSuccess: (state, { payload }) => {
      state.isPending = false;
      const { id } = payload.inResponseTo;
      state.paymentMethods = state.paymentMethods.map(method => ({
        ...method,
        is_active: method.id === id,
      }));
    },
    makeDefaultPaymentMethodFailure: state => {
      state.isPending = false;
    },
    addPaymentMethod(state, { payload }) {
      state.paymentMethods.push(payload);
    },
    setStep: (state, { payload }) => {
      state.step = payload;
    },
    resetCustomer: state => {
      state.customer = null;
      state.paymentMethods = [];
    },
    fetchAbstractCustomer: state => {
      state.isPending = true;
    },
    fetchAbstractCustomerSuccess: (state, { payload }) => {
      const { data } = payload;
      state.isPending = false;
      state.abstractCustomers[data.email] = data;
    },
    fetchAbstractCustomerFailure: state => {
      state.isPending = false;
    },
    archiveCustomer: state => {
      state.isPending = true;
    },
    archiveCustomerSuccess: (state, { payload }) => {
      const { data } = payload;
      state.customers[data.id].wine_club_members = data.wine_club_members;
      state.isPending = false;
    },
    archiveCustomerFailure: state => {
      state.isPending = false;
    },
    sendRecoverPasswordEmail: state => {
      state.isPending = true;
    },
    sendRecoverPasswordEmailSuccess: state => {
      state.isPending = false;
    },
    sendRecoverPasswordEmailFailure: state => {
      state.isPending = false;
    },
    restoreCustomer: state => {
      state.isPending = true;
    },
    restoreCustomerSuccess: (state, { payload }) => {
      const { data } = payload;
      state.customers[data.id].wine_club_members = data.wine_club_members;
      state.isPending = false;
    },
    restoreCustomerFailure: state => {
      state.isPending = false;
    },
    toggleWineClubMemberActivity: state => {
      state.wineClubMemberPending = true;
    },
    toggleWineClubMemberActivitySuccess: (state, { payload }) => {
      const { data, inResponseTo } = payload;
      state.wineClubMemberPending = false;
      state.customer.wine_club_members = (
        state?.customer?.wine_club_members ?? []
      ).map(member =>
        member.id === inResponseTo ? { ...data, id: inResponseTo } : member
      );
    },
    toggleWineClubMemberActivityFailure: state => {
      state.wineClubMemberPending = false;
    },
    sendCustomEmail: state => {
      state.isPending = true;
    },
    sendCustomEmailSuccess: (state, { payload }) => {
      const { data } = payload;
      data.ids.map(
        // eslint-disable-next-line no-return-assign
        id =>
          (state.customers[id].last_email = {
            sent_at: data.sent_at,
            template: data.template_title,
          })
      );
      state.isPending = false;
    },
    sendCustomEmailFailure: state => {
      state.isPending = false;
    },
  },
  extraReducers: {
    [subscriptionsActions.createSubscriptionSuccess]: (state, { payload }) => {
      const { data, inResponseTo } = payload;
      state.customer.wine_club_members = (
        state?.customer?.wine_club_members ?? []
      ).map(member =>
        member.wineclub_id === inResponseTo?.wineclub_id
          ? {
              ...member,
              subscripted_tier: data?.tier,
              is_gift: data?.is_gift,
              gift_note: data?.gift_note,
              obligation_id: data?.id,
              tier_cancel_date: null,
              tier_cancel_reason: null,
            }
          : member
      );
    },
    [subscriptionsActions.cancelSubscriptionSuccess]: (state, { payload }) => {
      const { data, inResponseTo } = payload;
      state.customer.wine_club_members = (
        state?.customer?.wine_club_members ?? []
      ).map(member =>
        member.obligation_id === inResponseTo?.id
          ? {
              ...member,
              subscripted_tier: null,
              is_gift: data?.is_gift,
              gift_note: data?.gift_note,
              obligation_id: data?.id,
              tier_cancel_date: data?.updated_at,
              tier_cancel_reason: data?.cancel_reason,
            }
          : member
      );
    },
  },
});

export const actions = actionTypes(customersSlice.actions);

export default customersSlice.reducer;
