import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import useAction from 'hooks/useAction';
import { actions as usersActions } from 'models/user/slice';
import BrandsPaginatedField from 'components/PaginatedFields/BrandsPaginatedField';
import PopupBase from 'components/Popup/PopupBase';
import { useSelector } from 'react-redux';
import { isOpenSuccessModalSelector } from 'models/common/selectors';
import { actions as commonActions } from 'models/common/slice';
import styles from './RequestPaymentTable.scss';

const RequestPaymentTable = ({
  organizationId,
  paymentMethods,
  contactEmail,
  title,
}) => {
  // Actions
  const requestPaymentInformation = useAction(
    usersActions.requestPaymentInformation
  );
  const setIsOpenSuccessModal = useAction(commonActions.setIsOpenSuccessModal);

  // Selectors
  const isOpenSuccessModal = useSelector(isOpenSuccessModalSelector);

  // States
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [isBrandAlreadyExists, setIsBrandAlreadyExists] = useState(false);

  // Effects
  useEffect(() => {
    if (selectedBrand) {
      const exists = paymentMethods.some(
        method => method.brand_title === selectedBrand.label
      );
      setIsBrandAlreadyExists(exists);
    }
  }, [selectedBrand, paymentMethods]);

  // Handlers
  const handleRequestPayment = () => {
    if (selectedBrand) {
      setIsOpenSuccessModal(true);
      requestPaymentInformation({
        organization_id: organizationId,
        brand_id: selectedBrand.value,
      });
    }
  };

  return (
    <Row>
      <Col>
        <Card className={styles.requestPaymentWrapper}>
          <CardBody>
            <h4 className="card-title mb-3">{title}</h4>
            <Row className="mb-3">
              <Col className={styles.brandContainer}>
                <BrandsPaginatedField
                  onChange={setSelectedBrand}
                  isMulti={false}
                  defaultValue={null}
                  placeholder="Select a Brand"
                />
              </Col>
              <Col>
                <Button
                  color="primary"
                  disabled={!selectedBrand}
                  onClick={handleRequestPayment}
                >
                  Send email
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      {isOpenSuccessModal && (
        <PopupBase onClose={() => setIsOpenSuccessModal(false)}>
          <p className={styles.pMargin}>
            {isBrandAlreadyExists
              ? `An email to update the account details has been sent to the shared customer at ${contactEmail}.`
              : `An email to signup as a shared customer has been sent to ${contactEmail}.`}
          </p>
          <Button
            color="primary"
            className="mx-auto mt-3 mb-2"
            onClick={() => setIsOpenSuccessModal(false)}
          >
            OK
          </Button>
        </PopupBase>
      )}
    </Row>
  );
};

RequestPaymentTable.propTypes = {
  organizationId: PropTypes.number.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  contactEmail: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default RequestPaymentTable;
