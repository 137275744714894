import { createSelector } from 'reselect';

export const rootSelector = state => state.customers;

export const isPaymentPendingSelector = createSelector(
  rootSelector,
  ({ paymentPending }) => paymentPending
);

export const customersSelector = createSelector(rootSelector, ({ customers }) =>
  customers ? Object.values(customers) : []
);

export const customersIdsSelector = createSelector(
  rootSelector,
  ({ customersIds }) => {
    return customersIds;
  }
);

export const provincesSelector = createSelector(
  rootSelector,
  ({ provinces }) => provinces
);

export const paginationSelector = createSelector(
  rootSelector,
  ({ pagination }) => pagination
);

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const wineClubMemberPendingSelector = createSelector(
  rootSelector,
  ({ wineClubMemberPending }) => wineClubMemberPending
);

export const isPendingNotesSelector = createSelector(
  rootSelector,
  ({ notesPending }) => notesPending
);

export const stepSelector = createSelector(rootSelector, ({ step }) => step);

export const customerSelector = createSelector(
  rootSelector,
  ({ customer }) => customer
);

export const intentSelector = createSelector(
  rootSelector,
  ({ intent }) => intent
);

export const intentFailureSelector = createSelector(
  rootSelector,
  ({ intentFailure }) => intentFailure
);

export const paymentMethodsSelector = createSelector(
  rootSelector,
  ({ paymentMethods }) => paymentMethods
);

export const paymentTypesSelector = createSelector(
  rootSelector,
  ({ paymentTypes }) => paymentTypes
);

export const paymentTypesAvailableSelector = createSelector(
  rootSelector,
  ({ paymentTypesAvailable }) => paymentTypesAvailable
);

export const customersInvitedSelector = createSelector(
  rootSelector,
  ({ customersInvited }) => customersInvited
);

export const abstractCustomerByEmailSelector = email =>
  createSelector(
    rootSelector,
    ({ abstractCustomers }) => abstractCustomers?.[email]
  );
