import React, { useState, useEffect } from 'react';
import PhoneField from 'components/Fields/PhoneField/PhoneField';
import PropTypes from 'prop-types';
import ContactEditActionDropdown from './ContactEditActionDropdown';
import styles from 'components/OrganizationForm/OrganizationForm.scss';

const ContactPersonCard = ({
  id,
  onChange,
  onDelete,
  defaultValue,
  makePaymentContact,
  disabled,
}) => {
  const [contact, setContact] = useState(defaultValue);
  const [errors, setErrors] = useState({}); // Track validation errors

  const validateField = (field, value) => {
    if (!value) {
      return 'This field is required';
    }
    if (field === 'email' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      return 'Invalid email address';
    }
    return '';
  };

  useEffect(() => {
    // Update local state only when `defaultValue` actually changes
    if (JSON.stringify(contact) !== JSON.stringify(defaultValue)) {
      setContact(defaultValue);
    }
  }, [defaultValue]);

  const handleInputChange = (field, value) => {
    const error = validateField(field, value);
    setErrors(prevErrors => ({ ...prevErrors, [field]: error }));
    const updatedContact = { ...contact, [field]: value };
    setContact(updatedContact);
    onChange(updatedContact);
  };

  return (
    <tr>
      <td className={styles.dataCell}>
        <div>
          <input
            className={`form-control ${
              errors.first_name ? styles.errorInput : ''
            }`}
            name={`contact_people.${id}.first_name`}
            placeholder="First Name"
            isRequired
            value={contact.first_name}
            onChange={({ target }) =>
              handleInputChange('first_name', target.value)
            }
          />
          {errors.first_name && (
            <span className={styles.errorMessage}>{errors.first_name}</span>
          )}
        </div>
      </td>
      <td className={styles.dataCell}>
        <div>
          <input
            className={`form-control ${
              errors.last_name ? styles.errorInput : ''
            }`}
            name={`contact_people.${id}.last_name`}
            placeholder="Last Name"
            isRequired
            value={contact.last_name}
            onChange={({ target }) =>
              handleInputChange('last_name', target.value)
            }
          />
          {errors.last_name && (
            <span className={styles.errorMessage}>{errors.last_name}</span>
          )}
        </div>
      </td>
      <td className={styles.dataCell}>
        <div>
          <input
            type="email"
            className={`form-control ${errors.email ? styles.errorInput : ''}`}
            name={`contact_people.${id}.email`}
            placeholder="Email"
            isRequired
            value={contact.email}
            onChange={({ target }) => handleInputChange('email', target.value)}
          />
          {errors.email && (
            <span className={styles.errorMessage}>{errors.email}</span>
          )}
        </div>
      </td>
      <td className={styles.dataCell}>
        <div>
          <PhoneField
            name={`contact_people.${id}.phone`}
            placeholder="Phone"
            isRequired
            value={contact.phone}
            onChange={value => handleInputChange('phone', String(value || ''))}
          />
          {errors.phone && (
            <span className={styles.errorMessage}>{errors.phone}</span>
          )}
        </div>
      </td>
      <td className={styles.centered}>
        {contact.is_payment_contact ? <p>Payment Contact</p> : <p>-</p>}
      </td>
      <td className={styles.actionColumn}>
        <ContactEditActionDropdown
          onDelete={onDelete}
          handlePaymentContact={() => makePaymentContact(id)}
          disabled={disabled}
          isPaymentContact={contact.is_payment_contact}
        />
      </td>
    </tr>
  );
};

ContactPersonCard.propTypes = {
  id: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  defaultValue: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    is_payment_contact: PropTypes.bool,
  }).isRequired,
  makePaymentContact: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ContactPersonCard;
